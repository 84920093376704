// 结构页面
import React from 'react'
import { Result } from 'antd';

const NotFind = () => {
    return (
        <div className="">
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
            />,
        </div>
    );
};
export default NotFind;