// 登录相关请求
import req from './index'

//获取扣分审核记录
export const getViolationsAuditFun = (params) => {
  return req.post('/?service=Admin.Clgls.DeductionRecord_List', params)
}

//修改扣分审核状态
export const setViolationsAuditStateFun = (params) => {
  return req.post('/?service=Admin.Clgls.DeductionRecord_Examine', params)
}


