import React from "react";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import './App.css';
import RouterList from './router/index.js'
import 'antd/dist/reset.css';
import { startPath } from '@/router/config'

function App () {
  const navigate = useNavigate();
  const token = localStorage.getItem('vehicle_management_IsLogin');
  useEffect(() => {
    if (!token) {
      navigate(startPath, { replace: true })
    }
    //eslint-disable-next-line
  }, [token])
  return (
    <RouterList></RouterList>
  );
}

export default App;
