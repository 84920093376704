import React from "react";
import { Navigate } from 'react-router-dom'

import Login from '@/pages/login'
import Structure from '@/pages/structure'
import QRCode from '@/pages/qrcode/index.js'
import ElectricVehicleAudit from '@/pages/electric_vehicle_audit/index.js'
import Vehicle from '@/pages/vehicle/index.js'
import VehicleManager from '@/pages/vehicle_manager/index.js'
import ViolationsAudit from '@/pages/violations_audit/index.js'
import ViolationsDetails from '@/pages/violations_details/index.js'
import Statistics from '@/pages/statistics/index.js'
import Query from '@/pages/query/index.js'
import Test from '@/pages/test/index.js'

export const basicPath = '/admin';//基础路径
export const startPath = '/login';//初始路径
export const redirectPath = '/login';//主页重定向路径
export const StartComp = Login;

export const RouterConfig = [
  { path: "/login", title: "登录", exact: true, component: Login },
  {
    path: "/", title: "框架", exact: true, component: Structure,
    children: [
      { path: "/qrcode", title: "二维码", exact: true, component: QRCode },
      { path: "/electric_vehicle_audit", title: "审核", exact: true, component: ElectricVehicleAudit },
      { path: "/vehicle", title: "电动车", exact: true, component: Vehicle },
      { path: "/violations_audit", title: "扣分审核", exact: true, component: ViolationsAudit },
      { path: "/query", title: "查询", exact: true, component: Query },
      { path: "/vehicle_manager", title: "车辆管理员", exact: true, component: VehicleManager },
      { path: "/violations_details", title: "扣分细则", exact: true, component: ViolationsDetails },
      { path: "/statistics", title: "统计", exact: true, component: Statistics },
    ]
  },
  { path: "/test", title: "测试", exact: true, component: Test },
]
