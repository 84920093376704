// 登录相关请求
import req from './index'

//登录
export const getElectricVehicleAuditFun = (params) => {
  return req.post('/?service=Admin.Clgls.Examine_List', params)
}

//修改 审核状态 0 驳回  1 初始通过
export const setAuditStateFun = (params) => {
  return req.post('/?service=Admin.Clgls.Examine_SetPass', params)
}

//获取 | 修改 审核信息
export const handleAuditInfoFun = (params) => {
  return req.post('/?service=Admin.Clgls.Examine_Set', params)
}

// 设置每日复审人数上限
export const setUsersNumber = (params) => {
  return req.post('/?service=Admin.Clgls.ExamineNumber_Set', params)
}
