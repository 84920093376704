//复审
import { useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { App, theme, Table, Space, Tag, Pagination, Modal, Drawer, Button, Image as AntImage, Input, DatePicker } from 'antd'
import { PictureTwoTone, SearchOutlined } from '@ant-design/icons'
import { getElectricVehicleAuditFun, setAuditStateFun } from '@/server/electric_vehicle_audit'
import dayjs from 'dayjs';
import AuditStudent from './audit_student';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

function AgainAudit (props, ref) {
  useImperativeHandle(props.onRef, () => {
    return {
      getTableData
    };
  });
  const navigate = useNavigate();//路由跳转
  const location = useLocation();//路由接受参数

  const searchInput = useRef(null);//搜索框数据
  const { message } = App.useApp();
  const {
    token: { colorText, colorPrimary, colorTextQuaternary, colorTextTertiary },
  } = theme.useToken(); //获取主题颜色

  const [loading, setLoading] = useState(false)//加载中···
  const [siftData, setSiftData] = useState({//搜索列表
    name: '',
    endDate: '',
    startDate: '',
    page: 1,
    limit: 10,
  });
  const [imgVisible, setImgVisible] = useState({//图片展示
    visible: false,
    url: '',
    url2: '',
  });
  const [auditDrawer, setAuditDrawer] = useState({//审核抽屉
    isOpen: false,
    id: '',//选择的学员id
  });
  const [rejectedCauseModal, setRejectedCauseModal] = useState({
    isOpen: false,
    value: '',
  });//驳回原因弹窗

  const [tableData, setTableData] = useState([]);//表格数据
  const [total, setTotal] = useState(0);//表格数据

  const auditStudentRef = useRef();//审核学员

  //获取表格数据
  const getTableData = async (sift) => {
    console.log('111', sift, siftData);
    setLoading(true);
    if (!sift) {
      setSiftData({
        page: 1,
        limit: 10,
        name: siftData.name,
        endDate: siftData.end_time,
        startDate: siftData.start_time,
      });
    }
    let res = await getElectricVehicleAuditFun({
      type: 2,
      name: sift ? sift.name : siftData.name,
      end_time: sift ? sift.endDate : siftData.endDate,
      start_time: sift ? sift.startDate : siftData.startDate,
      page: sift ? sift.page : 1,
      perpage: sift ? sift.limit : 10
    });
    console.log('res', res);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
    }
    let audit = [];
    if (res.data.data.items && res.data.data.items.length > 0) {
      for (let i = 0; i < res.data.data.items.length; i++) {
        let val = res.data.data.items[i];
        let obj = {
          id: val.wId,
          userType: val.role,
          name: val.name,
          phone: val.phone,
          code: val.swid,
          carType: val.car_type_txt,
          carTypeValue: val.car_type,
          licensePlate: val.car_number,
          carImg: val.pic,
          carImg2: val.side_pic,
          submitDate: val.submit_time,
          state: val.is_approved,
          noticeDate: val.notice_time
        }
        audit.push(obj);
      }
    }
    setTableData(audit);
    setTotal(res.data.data.total);
    setLoading(false);
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const searchDate = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8, }} onKeyDown={(e) => e.stopPropagation()} >
        <RangePicker value={selectedKeys}
          onChange={(e) => { setSelectedKeys(e ? e : []); }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8 }} />
        <div className='tr'>
          <Button type="primary" className='marR8'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />} size="small" style={{ width: 90, }}
          >
            筛选
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)}
            size="small" style={{ width: 90, }}
          >
            重置
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
  });

  // 输入框搜索
  const handleSearch1 = (confirm, dataIndex) => {
    confirm();
  };

  //重置
  const handleReset1 = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };

  //姓名、手机 输入框搜索
  const getColumSearch = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      < div className='pd8' onKeyDown={(e) => e.stopPropagation()} >
        <div>
          <Input ref={searchInput}
            placeholder={`搜索 ${dataIndex}`}
            value={selectedKeys}
            onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
            onPressEnter={() => handleSearch1(confirm)}
          />
        </div>

        <Space className='pdT8 flex jcsb'>
          <Button
            type="primary"
            onClick={() => handleSearch1(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            搜索
          </Button>
          <Button
            onClick={() => handleReset1(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            重置
          </Button>
        </Space>
      </ div >
    ),
    filterIcon: (filtered) => (<SearchOutlined style={{ color: filtered ? '#1890ff' : '' }} />),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  })


  //表格头
  const columns = [
    {
      title: "类型",
      dataIndex: "userType",
      render: (text, record) => {
        return (
          text ? <div>{text}</div >
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "姓名",
      dataIndex: "name",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
      ...getColumSearch('姓名')
    },
    {
      title: "手机",
      dataIndex: "phone",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "学号/工号",
      dataIndex: "code",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "车辆类型",
      dataIndex: "carType",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "车牌号",
      dataIndex: "licensePlate",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "车辆图片",
      dataIndex: "carImg",
      align: 'center',
      render: (text, record) => {
        return (
          text ?
            <PictureTwoTone
              className='pointer'
              onClick={() => {
                let info = { ...imgVisible };
                info.visible = true;
                info.url = record.carImg;
                info.url2 = record.carImg2;
                setImgVisible(info);
              }}
            />
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "提交日期",
      dataIndex: "submitDate",
      type: '',
      render: (text, record) => {
        return (
          text ? <div style={{ color: colorTextTertiary }}>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "通知日期",
      dataIndex: "noticeDate",
      type: '',
      render: (text, record) => {
        return (
          text ? <div style={{ color: colorTextTertiary }}>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
      ...searchDate()
    },
    {
      title: "操作",
      align: 'center',
      render: (text, record) => {
        return <div className='pointer' style={{ color: colorPrimary }} onClick={() => {
          let info = { ...auditDrawer };
          info.isOpen = true;
          info.id = record.id;
          setAuditDrawer(info);
        }}>
          审核
        </div>
      },
    },
  ];

  //关闭审核抽屉
  const closeAuditDrawer = (refresh = false) => {
    let info = { ...auditDrawer };
    info.isOpen = false;
    setAuditDrawer(info);
    if (refresh) {
      console.log('执行');
      getTableData(siftData);
    }
  }

  //修改审核状态 - 驳回
  const setAuditState = async () => {
    setLoading(true);
    let res = await setAuditStateFun({
      wId: auditDrawer.id,
      type: 0,
      remarks: rejectedCauseModal.value
    });
    setLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    message.success('审核驳回');
    closeRejectedCauseModal();
    closeAuditDrawer(true);
  }

  //关闭驳回弹窗
  const closeRejectedCauseModal = () => {
    let info = { ...rejectedCauseModal };
    info.isOpen = false;
    info.value = '';
    setRejectedCauseModal(info);
  }

  useEffect(() => {
    // getTableData(siftData);
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Table rowKey='id' loading={loading} columns={columns} dataSource={tableData} pagination={false}
        onChange={(pagination, filters, sorter) => {
          console.log('filters', filters);
          let sift = { ...siftData };
          sift.page = 1;
          sift.name = filters.name
          sift.startDate = filters.noticeDate ? dayjs(filters.noticeDate[0]).format('YYYY-MM-DD') : '';
          sift.endDate = filters.noticeDate ? dayjs(filters.noticeDate[1]).format('YYYY-MM-DD') : '';
          getTableData(sift);
          setSiftData(sift);
        }} />

      {/* 页脚 */}
      <div className='pdT16 tr'>
        <Pagination current={siftData.page} pageSize={siftData.limit} total={total} showTotal={total => `共 ${total} 条数据`}
          showSizeChanger={true}
          onChange={
            (current, pageSize) => { //选择当前页后执行
              let sea = siftData;
              sea.page = current
              if (sea.limit !== pageSize) {
                sea.page = 1;
                sea.limit = pageSize;
              }
              setSiftData({ ...sea });
              getTableData(sea);
            }
          }
        />
      </div>

      <Drawer title="审核" width={388} placement="right" onClose={() => {
        closeAuditDrawer()
      }} open={auditDrawer.isOpen}
        destroyOnClose={true}
        extra={
          <Space>
            <Button onClick={() => {
              let info = { ...rejectedCauseModal };
              info.isOpen = true;
              info.value = '';
              setRejectedCauseModal(info);
            }}>驳回</Button>
            <Button type="primary" onClick={() => {
              auditStudentRef.current.submit();
            }}>
              通过
            </Button>
          </Space>
        }
      >
        <AuditStudent onRef={auditStudentRef} id={auditDrawer.id} type={1} close={closeAuditDrawer}></AuditStudent>
      </Drawer>

      {/* 图片放大 */}
      <AntImage.PreviewGroup
        preview={{
          visible: imgVisible.visible,
          // current: 1,
          onVisibleChange: (value) => {
            let info = { ...imgVisible };
            info.visible = false;
            info.url = '';
            info.url2 = '';
            setImgVisible(info);
          },
        }}
      >
        {imgVisible.url && <AntImage style={{ display: 'none', }} src={imgVisible.url} />}
        {imgVisible.url2 && <AntImage style={{ display: 'none', }} src={imgVisible.url2} />}
      </AntImage.PreviewGroup>

      <Modal title="驳回原因" open={rejectedCauseModal.isOpen} okText='驳回' destroyOnClose={true} onOk={() => {
        setAuditState()
      }} onCancel={() => {
        closeRejectedCauseModal();
      }}>
        <TextArea rows={4} value={rejectedCauseModal.value} placeholder="驳回原因" onChange={((e) => {
          let info = { ...rejectedCauseModal };
          info.value = e.target.value;
          setRejectedCauseModal(info);
        })} />
      </Modal>
    </div>
  )
}
export default AgainAudit;