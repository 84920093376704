// 审核
import { useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react'
import { Card, theme, Radio, Button, Drawer, Form, App, InputNumber, Space } from 'antd'
import { } from '@ant-design/icons'
import { setUsersNumber } from '@/server/electric_vehicle_audit'

import AgainAudit from './again_audit';
import FirstAudit from './first_audit';

function ElectricVehicleAudit() {
  const navigate = useNavigate();//路由跳转
  const location = useLocation();//路由接受参数

  const { message } = App.useApp();
  const {
    token: { colorText, colorPrimary },
  } = theme.useToken(); //获取主题颜色

  const [form] = Form.useForm();
  const [auditState, setAuditState] = useState(1);//审核状态
  const [upperLimit, setUpperLimit] = useState({//设置每日复审人数上限
    isOpen: false,
    loading: false,
    number: 0,
  });
  const [againAuditNumber, setAgainAuditNumber] = useState('');//复审人数

  const firstAuditRef = useRef();//初审
  const againAuditRef = useRef();//复审

  //修改复审人数
  const onFinish = async (e) => {
    let res = await setUsersNumber({ number: e.number || '' });

    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了！');
      return;

    }
    let upp = { ...upperLimit };
    upp.isOpen = false;
    setUpperLimit({ ...upp });
    setAgainAuditNumber(e.number);
    message.success("设置每日复审人数上限成功！");
  }


  useEffect(() => {
    // eslint-disable-next-line
  }, [])

  return (
    <Card className='mar24'>
      <div className='marB16 flex jcsb'>
        <Radio.Group value={auditState} onChange={(e) => {
          setAuditState(e.target.value);
          if (e.target.value === 1) {
            firstAuditRef.current.getTableData();
          }
          if (e.target.value === 2) {
            againAuditRef.current.getTableData();
          }
        }}>
          <Radio.Button value={1}>初审</Radio.Button>
          <Radio.Button value={2}>复审</Radio.Button>
        </Radio.Group>
        <Button
          style={{ display: auditState === 1 ? '' : 'none' }}
          type='primary'
          onClick={() => {
            let upp = upperLimit;
            upp.isOpen = true;
            setUpperLimit({ ...upp });
            form.setFieldsValue({
              number: againAuditNumber ? againAuditNumber : firstAuditRef.current.againAuditNumber
            })
          }}
        >
          设置每日复审人数上限
        </Button>
      </div>
      <div style={{ display: auditState === 1 ? '' : 'none' }}>
        <FirstAudit onRef={firstAuditRef}></FirstAudit>
      </div>
      <div style={{ display: auditState === 2 ? '' : 'none' }}>
        <AgainAudit onRef={againAuditRef}></AgainAudit>
      </div>


      {/* 设置每日复审人数上限 */}
      <Drawer width={256} open={upperLimit.isOpen}
        onClose={() => {
          let upp = upperLimit
          upp.isOpen = false
          setUpperLimit({ ...upp })
        }}
        extra={
          <Space>
            <Button type="primary" onClick={() => {
              document.getElementById('submitForm').click()
            }}>
              确定
            </Button>
          </Space>
        }
      >
        <Form form={form} onFinish={onFinish} layout="vertical" >
          <Form.Item name='number' label='复审人数/天' >
            <InputNumber min={0} className='wid100' placeholder='-' />
          </Form.Item>

          <Form.Item
          >
            <Button id='submitForm' className='none' type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </Card>
  )
}
export default ElectricVehicleAudit;