// 登录相关请求
import req from './index'

//获取车辆管理员
export const getVehicleManagerFun = (params) => {
  return req.post('/?service=Admin.Clgls.Manager_List', params)
}

//修改车辆管理员信息
export const setVehicleManagerInfoFun = (params) => {
  return req.post('/?service=Admin.Clgls.Manager_Set', params)
}

//修改车辆管理员的启用状态
export const setVehicleManagerStateFun = (params) => {
  return req.post('/?service=Admin.Clgls.Manager_Del', params)
}