// 结构页面
import React, { useRef } from 'react'
import { Outlet } from 'react-router-dom'
import { Layout, theme } from 'antd';
import Menu from './menu'
import Logo from '@/common/img/logo.svg'

function Structure() {
  const { Header, Sider, Content } = Layout;
  const {
    token: { colorBgContainer, colorText },
  } = theme.useToken(); //获取主题颜色

  return (
    <Layout className='hei100'>
      {/* 头部 */}
      <Header style={{ padding: '0', color: 'white' }}>
        <div className='pdRL16 fs24' style={{ fontWeight: 'bold' }}>
          西南石油大学非机动车、(电动)摩托车管理平台
        </div>

      </Header>
      <Layout>
        {/* 菜单 */}
        <Sider style={{ overflowY: 'auto', background: colorBgContainer }}>
          <Menu></Menu>
        </Sider>

        {/* 内容 */}
        <Content style={{ overflow: 'auto' }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>

  )
}
export default Structure