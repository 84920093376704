//当前浏览器为pc端显示
import React, { useEffect, useRef, useState } from 'react'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd';
import data from './package-lock.json'

function Text(props) {
  //路由
  const navigate = useNavigate();
  const listRef = useRef([]);

  const zip = new JSZip();

  const fetchAndZip = async (url, fileName) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      zip.file(fileName, blob, { binary: true });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    let arr = [];
    for (let key in data.dependencies) {
      let val = data.dependencies[key].resolved;
      let name = val.split('/')[val.split('/').length - 1];
      arr.push({
        url: val,
        name: name
      });
    }
    listRef.current = arr;
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Button onClick={() => {
        console.log(listRef.current);
        Promise.all(listRef.current.map((item, index) => fetchAndZip(item.url, item.name)))
          .then(() => {
            zip.generateAsync({ type: 'blob' })
              .then(blob => {
                saveAs(blob, 'downloaded_files.zip');
              });
          })
          .catch(e => {
            console.error(e);
          });
      }}>下载</Button>
    </div>
  )

}
export default Text
