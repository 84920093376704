// 二维码
import req from './index'

//获取二维码
export const getQRCodeFun = (params) => {
  return req.post('/?service=Admin.Clgls.Code_List', params)
}

//批量生成二维码
export const setQRCodeNumFun = (params) => {
  return req.post('/?service=Admin.Clgls.Code_Generate', params)
}

//获取二维码
export const getAllQRCodeFun = (params) => {
  return req.post('/?service=Admin.Clgls.Code_All', params)
}


