import React from "react";
import {
  UserOutlined, AuditOutlined, LoginOutlined, IdcardOutlined, QrcodeOutlined, BarChartOutlined, SearchOutlined,
  AlertOutlined, FileTextOutlined
} from '@ant-design/icons';

// label 菜单名称
// key 菜单主键 用于路由跳转
// icon 菜单图标
// children 子菜单 数组
// parent 父级菜单key
function getItem(label, key, icon, children, parent = '') {
  return {
    key,
    icon,
    children,
    label,
    parent
  };
}

export const MenuList = [
  getItem('二维码', '/qrcode', <QrcodeOutlined />, '', ''),
  getItem('审核', '/electric_vehicle_audit', <AuditOutlined />, '', ''),
  getItem('电动车', '/vehicle', <IdcardOutlined />, '', ''),
  getItem('扣分审核', '/violations_audit', <AlertOutlined />, '', ''),
  getItem('查询', '/query', <SearchOutlined />, '', ''),
  getItem('车辆管理员', '/vehicle_manager', <UserOutlined />, '', ''),
  getItem('扣分细则', '/violations_details', <FileTextOutlined />, '', ''),

  getItem('统计', '/statistics', <BarChartOutlined />, '', ''),
  getItem('退出登录', '/login', <LoginOutlined />, '', ''),
];

