// 扣分审核
import React, { useState, useEffect, useRef } from 'react'
import { Card, theme, Table, Input, Button, Tag, Pagination, Divider, Modal, Image as AntImage, Popover, App, Space } from 'antd'
import {
  CloseCircleOutlined, CheckCircleOutlined, SearchOutlined, FieldTimeOutlined, ExclamationCircleFilled,
  PictureTwoTone, MessageTwoTone
} from '@ant-design/icons'
import { getViolationsAuditFun, setViolationsAuditStateFun } from '@/server/violations_audit'

function ViolationsAudit() {
  const { message } = App.useApp();
  const {
    token: { colorTextQuaternary, colorTextTertiary, colorPrimary },
  } = theme.useToken(); //获取主题颜色
  const { confirm } = Modal;

  const searchInput = useRef(null);//搜索框数据

  const [siftData, setSiftData] = useState({ //筛选数据
    unit: '',
    userType: '',
    name: '',
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);//表格数据
  const [total, setTotal] = useState(0);//总数
  const [loading, setLoading] = useState(false);

  const [unitOption, setUnitOption] = useState([]);//单位
  const [userTypeOption, setUserTypeOption] = useState([]);//用户类型

  const [imgVisible, setImgVisible] = useState({//图片展示
    visible: false,
    url: [],
  });


  // 输入框搜索
  const handleSearch = (confirm) => {
    confirm()
  };

  //重置
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };
  //姓名、手机、学院 输入框搜索
  const getColumSearch = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      < div className='pd8' onKeyDown={(e) => e.stopPropagation()} >
        <div>
          <Input ref={searchInput}
            placeholder={`搜索 ${dataIndex}`}
            value={selectedKeys}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(confirm)}
          />
        </div>
        <Space className='pdT8 flex jcsb'>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            搜索
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            重置
          </Button>
        </Space>
      </ div >
    ),
    filterIcon: (filtered) => (<SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  })

  //表格头
  const columns = [
    {
      title: "扣分",
      dataIndex: "violationsScore",
      render: (text, record) => {
        return (
          text ? <div>{text}</div >
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "扣分项",
      dataIndex: 'violationsType',
      render: (text, record) => {
        return (
          text ? <div>{text}</div >
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "车牌号",
      dataIndex: "licensePlate",
      render: (text, record) => {
        return (
          text ? <div>{text}</div >
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      }
    },
    {
      title: "姓名",
      dataIndex: "name",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
      ...getColumSearch('姓名')
    },
    {
      title: "学院/单位",
      dataIndex: "unit",
      render: (text, record) => {
        return (
          text ? <div>{text}</div >
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
      filterMultiple: false,
      filters: unitOption
    },
    {
      title: "类型",
      dataIndex: "userType",
      render: (text, record) => {
        return (
          text ? <div>{text}</div >
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
      filterMultiple: false,
      filters: userTypeOption
    },
    {
      title: "图片",
      dataIndex: "img",
      width: 90,
      align: 'center',
      render: (text, record) => {
        return (
          text.length > 0 ?
            <div className='pointer' onClick={() => {
              let info = { ...imgVisible };
              info.visible = true;
              info.url = text;
              setImgVisible(info);
            }}><PictureTwoTone /></div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "提交日期",
      dataIndex: "submitDate",
      type: '',
      render: (text, record) => {
        return (
          text ? <div style={{ color: colorTextTertiary }}>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      align: 'center',
      width: 90,
      render: (text, record) => {
        return (
          <div>
            {text
              ? (
                <Popover trigger="click"
                  content={
                    <div>
                      {
                        text.split('\n').map((item, idx) => {
                          return <div key={idx}>{item}</div>
                        })
                      }
                    </div>
                  }
                >
                  <MessageTwoTone className='pointer' />
                </Popover>

              )
              : <div style={{ color: colorTextQuaternary }}>-</div>
            }
          </div>
        )
      },
    },
    {
      title: "状态",
      dataIndex: "state",
      width: 90,
      render: (text, record) => {
        return (
          record.stateValue === 2 ? <Tag icon={<CheckCircleOutlined />} color="success">
            {record.state}
          </Tag> : record.stateValue === 3 ? <Tag icon={<CloseCircleOutlined />} color="error">
            {record.state}
          </Tag> : <Tag icon={<FieldTimeOutlined />} color="warning">
            {record.state}
          </Tag>
        )
      },
    },
    {
      title: "操作",
      dataIndex: "",
      render: (text, record) => {
        return (
          <div className='flex_row align_center'>
            {
              record.stateValue === 1 ?
                <>
                  <div className='pointer' style={{ color: colorPrimary }} onClick={() => {
                    confirm({
                      title: '是否通过?',
                      icon: <ExclamationCircleFilled />,
                      content: '该管理员提交的扣分申请将通过，请谨慎操作!!!',
                      okText: '通过',
                      cancelText: "容我想想~",
                      async onOk() {
                        let params = {
                          id: record.id,
                          type: 2,
                          state: 2
                        };
                        await setViolationsAuditState(params);
                      },
                      onCancel() { },
                    });
                  }}>通过</div>
                  <Divider type="vertical" />
                  <div className='pointer' style={{ color: colorPrimary }} onClick={() => {
                    confirm({
                      title: '是否驳回?',
                      icon: <ExclamationCircleFilled />,
                      content: '该管理员提交的扣分申请将驳回，请谨慎操作!!!',
                      okText: '驳回',
                      cancelText: "容我想想~",
                      async onOk() {
                        let params = {
                          id: record.id,
                          type: 2,
                          state: 3
                        };
                        await setViolationsAuditState(params);
                      },
                      onCancel() { },
                    });
                  }}>驳回</div>
                  <Divider type="vertical" />
                </> : ''
            }
            <div className='pointer' style={{ color: colorPrimary }} onClick={() => {
              confirm({
                title: '是否删除?',
                icon: <ExclamationCircleFilled />,
                content: '该管理员提交的扣分申请将删除，请谨慎操作!!!',
                okText: '删除',
                okType: "danger",
                cancelText: "容我想想~",
                async onOk() {
                  let params = {
                    id: record.id,
                    type: 1,
                    state: ''
                  };
                  await setViolationsAuditState(params);
                },
                onCancel() { },
              });
            }}>删除</div>
          </div>
        )
      },
    }
  ]

  //获取表格数据
  const getTableData = async (sift) => {
    let params = {
      page: sift.page,
      perpage: sift.limit,
      role: sift.userType,
      name: sift.name,
      user_unit: sift.unit
    }
    setLoading(true);
    let res = await getViolationsAuditFun(params);
    setLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    let arr = [];
    let user = res.data.sjzd.role.map((item) => {
      return {
        text: item.xmmc,
        value: Number(item.xmbm)
      }
    });
    let unit = res.data.sjzd.user_unit.map((item) => {
      return {
        text: item.xmmc,
        value: Number(item.xmbm)
      }
    });
    for (let i = 0; i < res.data.data.items.length; i++) {
      let val = res.data.data.items[i];
      let obj = {
        id: val.id,
        violationsScore: val.score,
        violationsType: val.title,
        licensePlate: val.car_number,
        name: val.name,
        unit: val.user_unitTxt,
        userType: val.roleTxt,
        submitDate: val.add_time,
        state: val.stateTxt,
        stateValue: Number(val.state),
        carType: val.carTypeTxt,
        role: Number(val.role),
        img: val.pic,
        remark: val.remarks
      };
      arr.push(obj);
    }

    setTableData(arr);
    setUnitOption(unit);
    setUserTypeOption(user);
    setTotal(res.data.data.total);
  }

  //修改状态
  const setViolationsAuditState = async (params) => {
    let res = await setViolationsAuditStateFun(params);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    let sift = { ...siftData };
    sift.page = 1;
    setSiftData(sift);
    getTableData(sift);
  }

  useEffect(() => {
    getTableData(siftData);
    // eslint-disable-next-line 
  }, [])

  return (
    <Card className='mar24'>
      <Table
        rowKey='id'
        loading={loading}
        columns={columns}
        pagination={false}
        dataSource={tableData}
        onChange={(pagination, filters, sorter) => {
          let sift = { ...siftData };
          sift.page = 1;
          for (let key in filters) {
            if (filters[key]) {
              sift[key] = filters[key][0]
            } else {
              sift[key] = '';
            }
          }
          setSiftData({ ...sift });
          getTableData(sift);
        }}
      />

      {/* 页脚 */}
      <div className='pdT16 tr'>
        <Pagination current={siftData.page} pageSize={siftData.limit} total={total} showTotal={total => `共 ${total} 条数据`}
          showSizeChanger={true}
          onChange={
            (current, pageSize) => { //选择当前页后执行
              let sift = { ...siftData }
              sift.page = current;
              if (sift.limit !== pageSize) {
                sift.page = 1;
                sift.limit = pageSize;
              }
              setSiftData({ ...sift });
              getTableData(sift);
            }
          }
        />
      </div>

      {/* 图片放大 */}
      <AntImage.PreviewGroup
        preview={{
          visible: imgVisible.visible,
          // current: 1,
          onVisibleChange: (value) => {
            let info = { ...imgVisible };
            info.visible = false;
            info.img = [];
            setImgVisible(info);
          },
        }}
      >
        {
          imgVisible.url.map((item, idx) => {
            return <AntImage key={idx} style={{ display: 'none', }} src={item} />
          })
        }
      </AntImage.PreviewGroup>
    </Card>
  )
}
export default ViolationsAudit