import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { basicPath } from '@/router/config'
import store from './store';
import { ConfigProvider, theme, App as AntApp } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';

// import '@/common/css/common.css' // 全局样式
import '@/common/css/baseCss.css' // 全局样式

//theme.defaultAlgorithm 明亮主题
//theme.darkAlgorithm 暗黑主题

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
        // token: { colorPrimary: '#00b96b' },
      }}
      locale={zhCN}
    >
      <AntApp style={{ height: '100%' }}>
        <BrowserRouter basename={basicPath}>
          <React.Suspense>
            <App />
          </React.Suspense>
        </BrowserRouter>
      </AntApp>
    </ConfigProvider>
  </Provider >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
