//编辑车辆管理员
import { useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useEffect, useImperativeHandle, useRef } from 'react'
import { theme, Form, App, Spin, Input, Select } from 'antd'
import { setVehicleManagerInfoFun } from '@/server/vehicle_manager'

function SetVehicleManager(props, ref) {
  useImperativeHandle(props.onRef, () => {
    return {
      submit,
      reset,
      form
    };
  });

  const { message } = App.useApp();
  const {
    token: { colorText, colorPrimary, colorTextSecondary, colorTextTertiary },
  } = theme.useToken(); //获取主题颜色
  const chooseId = props.id;//选择的id
  const typeOption = props.type;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);//加载动画

  ///初始化
  const initialize = async () => {
    setLoading(true);
    let res = await setVehicleManagerInfoFun({
      id: chooseId,
      type: 1
    });
    console.log(res);
    let info = res.data;
    setLoading(false);
    form.setFieldsValue({
      type: Number(info.role_type),
      wxid: info.wId,
      name: info.name,
      phone: info.phone
    })
  }

  // 提交
  const onFinish = async (e) => {
    setLoading(true);
    let res = await setVehicleManagerInfoFun({
      id: chooseId,
      type: 2,
      wId: e.wxid,
      name: e.name,
      phone: e.phone,
      role_type: e.type
    });
    setLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
    }
    props.close(true);
  }

  const reset = () => {
    form.resetFields();
  }

  //提交
  const submit = () => {
    form.submit();
  }

  useEffect(() => {
    if (chooseId) {
      initialize();
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Spin spinning={loading}>
        <Form
          layout='vertical'
          form={form}
          onFinish={onFinish}
        >
          <Form.Item label="类型" name='type' rules={[
            { required: true, message: '请选择类型', },
          ]}>
            <Select placeholder="-"
              options={
                typeOption.map((item) => {
                  return {
                    value: item.value,
                    label: item.text
                  }
                })
              }>

            </Select>
          </Form.Item>

          <Form.Item label="微信ID" name='wxid' rules={[
            { required: true, message: '请输入微信ID', },
          ]}>
            <Input placeholder="-" />
          </Form.Item>

          <Form.Item label="姓名" name='name' rules={[
            { required: true, message: '请输入姓名!', },
          ]}>
            <Input placeholder="-" />
          </Form.Item>

          <Form.Item label="手机" name='phone' rules={[
            { required: true, message: '请输入手机!', },
            { pattern: new RegExp(/^1[3456789]\d{9}$/), message: '请输入正确的手机号', },
          ]}>
            <Input placeholder="-" />
          </Form.Item>
        </Form>
      </Spin>
    </div>
  )
}
export default SetVehicleManager;