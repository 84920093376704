// 扣分细则
import React, { useState, useEffect, useRef } from 'react'
import { Card, theme, Table, Pagination, Image as AntImage, } from 'antd'
import { getViolationsDetailsFun } from '@/server/violations_details'

function ViolationsDetails() {
  const {
    token: { colorTextQuaternary },
  } = theme.useToken(); //获取主题颜色

  const [siftData, setSiftData] = useState({ //筛选数据
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);//表格数据
  const [total, setTotal] = useState(0);//总数
  const [loading, setLoading] = useState(false);

  //表格头
  const columns = [
    {
      title: "分值",
      dataIndex: "score",
      render: (text, record) => {
        return (
          text ? <div>{text}</div >
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "简称",
      dataIndex: 'abbreviation',
      render: (text, record) => {
        return (
          text ? <div>{text}</div >
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "详细描述",
      dataIndex: "detailed",
      render: (text, record) => {
        return (
          text ? <div>{text}</div >
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      }
    }
  ]

  //获取表格数据
  const getTableData = async (sift) => {
    let params = {
      page: sift.page,
      perpage: sift.limit
    }
    setLoading(true);
    let res = await getViolationsDetailsFun(params);
    setLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    let arr = [];
    for (let i = 0; i < res.data.length; i++) {
      let val = res.data[i];
      let obj = {
        id: val.id,
        score: val.score,
        abbreviation: val.title,
        detailed: val.des,
      };
      arr.push(obj);
    }

    setTableData(arr);
    setTotal(res.data.total);
  }

  useEffect(() => {
    getTableData(siftData);
    // eslint-disable-next-line 
  }, [])

  return (
    <Card className='mar24'>
      <Table
        rowKey='id'
        loading={loading}
        columns={columns}
        pagination={false}
        dataSource={tableData}
      />
    </Card>
  )
}
export default ViolationsDetails