//初审
import { useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { theme, Table, Space, Tag, Pagination, Image, Modal, App, Input, Button } from 'antd'
import {
  SearchOutlined,
  FieldTimeOutlined,
  CloseCircleOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons'

import { getElectricVehicleAuditFun, setAuditStateFun } from '@/server/electric_vehicle_audit'

import UserInfo from './user_info';

const { TextArea } = Input;
const { confirm } = Modal;

function FirstAudit (props, ref) {
  useImperativeHandle(props.onRef, () => {
    return {
      getTableData,
      againAuditNumber,
    };
  });
  const navigate = useNavigate();//路由跳转
  const location = useLocation();//路由接受参数

  const searchInput = useRef(null);//搜索框数据
  const { message } = App.useApp();
  const {
    token: { colorText, colorPrimary, colorTextQuaternary, colorTextTertiary },
  } = theme.useToken(); //获取主题颜色

  const [loading, setLoading] = useState(false)//加载中···
  const [siftData, setSiftData] = useState({//搜索列表
    name: '',
    page: 1,
    limit: 10,
    car_type: '',
  });
  const [againAuditNumber, setAgainAuditNumber] = useState(0);
  const [tableData, setTableData] = useState([]);//表格数据
  const [total, setTotal] = useState(0);//总条数

  const [imgVisible, setImgVisible] = useState({//图片展示
    visible: false,
    url: '',
  });

  const [rejectedCauseModal, setRejectedCauseModal] = useState({//驳回原因弹窗
    isOpen: false,
    value: '',
    id: '',
  });

  const [userInfoModal, setUserInfoModal] = useState({//用户信息弹窗
    isOpen: false,
    id: '',
  });

  //选项
  const [carTypeOption, setCarTypeOption] = useState([]);//车辆类型

  //获取表格数据
  const getTableData = async (sift) => {
    console.log(sift);
    setLoading(true);
    if (!sift) {
      setSiftData({ page: 1, limit: 10, car_type: siftData.car_type, name: siftData.name });
    }
    let res = await getElectricVehicleAuditFun({
      type: 1,
      name: sift ? sift.name : siftData.name,
      car_type: sift ? sift.car_type : siftData.car_type,
      page: sift ? sift.page : 1,
      perpage: sift ? sift.limit : 10
    });
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
    }
    let audit = [];
    let car_type = [];
    let dictionary = res.data.sjzd;
    if (res.data.data.items && res.data.data.items.length > 0) {
      for (let i = 0; i < res.data.data.items.length; i++) {
        let val = res.data.data.items[i];
        let obj = {
          id: val.wId,
          userType: val.role,
          name: val.name,
          phone: val.phone,
          unit: val.user_unit,
          code: val.swid,
          carType: val.car_type_txt,
          carTypeValue: val.car_type,
          licensePlate: val.car_number,
          carImg: val.pic,
          submitDate: val.submit_time,
          state: val.is_approved_txt,
          stateValue: Number(val.is_approved),
          auditDate: val.examine_time,
          rejectedCause: val.remarks || ''
        }
        audit.push(obj);
      }
    }
    if (car_type && dictionary.car_type) {
      for (let i = 0; i < dictionary.car_type.length; i++) {
        let val = dictionary.car_type[i];
        car_type.push({
          text: val.xmmc,
          value: val.xmbm,
        })
      }
    }
    setAgainAuditNumber(Number(dictionary.shsl));
    setTableData(audit);
    setTotal(res.data.data.total);
    setCarTypeOption(car_type);
    setLoading(false);
  }

  //修改审核状态
  const setAuditState = async (id, state, remark = '') => {
    setLoading(true);
    let res = await setAuditStateFun({
      wId: id,
      type: state,
      remarks: remark
    });
    setLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    message.success('修改成功');
    closeRejectedCauseModal();
    closeUserInfoModal();
    getTableData(siftData);
  }

  // 输入框搜索
  const handleSearch = (confirm, dataIndex) => {
    confirm();
  };

  //重置
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };

  //姓名、手机 输入框搜索
  const getColumSearch = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      < div className='pd8' onKeyDown={(e) => e.stopPropagation()} >
        <div>
          <Input ref={searchInput}
            placeholder={`搜索 ${dataIndex}`}
            value={selectedKeys}
            onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
            onPressEnter={() => handleSearch(confirm)}
          />
        </div>

        <Space className='pdT8 flex jcsb'>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            搜索
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            重置
          </Button>
        </Space>
      </ div >
    ),
    filterIcon: (filtered) => (<SearchOutlined style={{ color: filtered ? '#1890ff' : '' }} />),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  })

  const columns = [
    {
      title: "类型",
      dataIndex: "userType",
      render: (text, record) => {
        return (
          text ? <div>{text}</div >
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "姓名",
      dataIndex: "name",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
      ...getColumSearch('姓名')
    },
    {
      title: "手机",
      dataIndex: "phone",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "学号/工号",
      dataIndex: "code",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "车辆类型",
      dataIndex: "carType",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
      filterMultiple: false,
      filters: carTypeOption
    },
    // {
    //   title: "车牌号",
    //   dataIndex: "licensePlate",
    //   render: (text, record) => {
    //     return (
    //       text ? <div>{text}</div>
    //         : <div style={{ color: colorTextQuaternary }}>-</div>
    //     )
    //   },
    // },
    // {
    //   title: "车辆图片",
    //   dataIndex: "carImg",
    //   align: 'center',
    //   render: (text, record) => {
    //     return (
    //       text ?
    //         <PictureTwoTone
    //           className='pointer'
    //           onClick={() => {
    //             let info = { ...imgVisible };
    //             info.visible = true;
    //             info.url = text;
    //             setImgVisible(info);
    //           }}
    //         />
    //         : <div style={{ color: colorTextQuaternary }}>-</div>
    //     )
    //   },
    // },
    {
      title: "提交日期",
      dataIndex: "submitDate",
      render: (text, record) => {
        return (
          text ? <div style={{ color: colorTextTertiary }}>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "状态",
      dataIndex: "stateValue",
      render: (text, record) => {
        switch (text) {
          case 1:
            return (
              <Tag icon={<FieldTimeOutlined />} color="warning">
                {record.state}
              </Tag>
            )
          case 3:
            return (
              <Tag icon={<CloseCircleOutlined />} color="error">
                {record.state}
              </Tag>
            )
          default:
            return <div style={{ color: colorTextQuaternary }}>-</div>
        }

      },
    },
    {
      title: "审核日期",
      dataIndex: "auditDate",
      render: (text, record) => {
        return (
          text ? <div style={{ color: colorTextTertiary }}>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "驳回原因",
      dataIndex: "rejectedCause",
      render: (text, record) => {
        return (
          text ? <div style={{ color: colorTextTertiary }}>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "操作",
      align: 'center',
      width: 100,
      render: (text, record) => {
        return (
          record.stateValue === 1 ?
            <div className='pointer' style={{ color: colorPrimary }} onClick={() => {
              let info = { ...userInfoModal };
              info.isOpen = true;
              info.id = record.id;
              setUserInfoModal(info);
            }}>初审</div> :
            <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
  ];

  //关闭驳回弹窗
  const closeRejectedCauseModal = () => {
    let info = { ...rejectedCauseModal };
    info.isOpen = false;
    info.value = '';
    setRejectedCauseModal(info);
  }

  //关闭用户信息弹窗
  const closeUserInfoModal = () => {
    let info = { ...userInfoModal };
    info.isOpen = false;
    info.id = '';
    setUserInfoModal(info);
  }



  useEffect(() => {
    getTableData(siftData);
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Table rowKey='id' loading={loading} columns={columns} dataSource={tableData} pagination={false} onChange={(pagination, filters, sorter) => {
        console.log(pagination, filters, sorter);
        let sift = { ...siftData };
        sift.page = 1;
        sift.name = filters.name
        sift.car_type = filters.carType ? filters.carType[0] : '';
        console.log(sift);
        setSiftData(sift);
        getTableData(sift);
      }} />

      {/* 页脚 */}
      <div className='pdT16 tr'>
        <Pagination current={siftData.page} pageSize={siftData.limit} total={total} showTotal={total => `共 ${total} 条数据`}
          showSizeChanger={true}
          onChange={
            (current, pageSize) => { //选择当前页后执行
              let sift = { ...siftData }
              sift.page = current;
              if (sift.limit !== pageSize) {
                sift.page = 1;
                sift.limit = pageSize;
              }
              setSiftData({ ...sift })
              getTableData(sift)
            }
          }
        />
      </div>

      <Image style={{ display: 'none', }}
        preview={{
          visible: imgVisible.visible,
          src: imgVisible.url,
          onVisibleChange: (value) => {
            let info = { ...imgVisible };
            info.visible = false;
            setImgVisible(info);
          },
        }}
      />

      <Modal title="驳回原因" open={rejectedCauseModal.isOpen} okText='驳回' destroyOnClose={true} onOk={() => {
        setAuditState(rejectedCauseModal.id, 0, rejectedCauseModal.value);
      }} onCancel={() => {
        closeRejectedCauseModal();
      }}>
        <TextArea rows={4} value={rejectedCauseModal.value} placeholder="驳回原因" onChange={((e) => {
          let info = { ...rejectedCauseModal };
          info.value = e.target.value;
          setRejectedCauseModal(info);
        })} />
      </Modal>

      {/* 用户信息 */}
      <Modal title="" width={576} open={userInfoModal.isOpen} destroyOnClose={true} closable={false}
        styles={{ content: { padding: 16 } }} okText='通过' cancelText='驳回'
        onCancel={() => { closeUserInfoModal(); }}
        footer={() => {
          return (
            <>
              <Button onClick={() => {
                let info = { ...rejectedCauseModal };
                info.isOpen = true;
                info.value = '';
                info.id = userInfoModal.id;
                setRejectedCauseModal(info);
              }}>驳回</Button>
              <Button type="primary" onClick={() => {
                confirm({
                  title: '是否通过?',
                  icon: <ExclamationCircleFilled />,
                  content: '该用户提交的车辆申请将通过，请谨慎操作!!!',
                  okText: '通过',
                  cancelText: "容我想想~",
                  async onOk () {
                    setAuditState(userInfoModal.id, 1);
                  },
                  onCancel () { },
                });
              }}>通过</Button>
            </>
          )
        }}
      >
        <UserInfo id={userInfoModal.id}></UserInfo>
      </Modal>
    </div>
  )
}
export default FirstAudit;