//车辆管理员
import { useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react'
import { Card, theme, Table, Button, Divider, Switch, Pagination, Modal, Drawer, Space, App } from 'antd'
import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons'

import { getVehicleManagerFun, setVehicleManagerStateFun } from '@/server/vehicle_manager'

import SetVehicleManager from './set_vehicle_manager.js'

function VehicleManager() {
  const navigate = useNavigate();//路由跳转
  const location = useLocation();//路由接受参数

  const { message } = App.useApp();
  const {
    token: { colorPrimary, colorTextQuaternary },
  } = theme.useToken(); //获取主题颜色

  const { confirm } = Modal;

  const [loading, setLoading] = useState(false)//加载中···
  const [siftData, setSiftData] = useState({//搜索列表
    type: '',
    page: 1,
    limit: 10,
  });
  const [drawer, setDrawer] = useState({
    isOpen: false,
    title: '',
    id: '',
  });

  const [tableData, setTableData] = useState([])//table数据
  const [total, setTotal] = useState(0);//总条数

  const [typeOption, setTypeOption] = useState([]);

  const setVehicleManagerRef = useRef();

  //获取表格数据
  const getTableData = async (sift) => {
    setLoading(true);
    let res = await getVehicleManagerFun({
      role_type: sift.type,
      page: sift.page,
      perpage: sift.limit
    });
    console.log(res);
    setLoading(false)
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
    }
    let arr = [];
    let type = res.data.sjzd.glylx.map((item) => {
      return {
        text: item.xmmc,
        value: Number(item.xmbm)
      }
    })
    for (let i = 0; i < res.data.data.items.length; i++) {
      let val = res.data.data.items[i];
      let obj = {
        idx: i,
        id: val.id,
        type: val.role_type,
        wxid: val.wId,
        name: val.name,
        phone: val.phone,
        isEnable: Number(val.is_enabled) === 1 ? true : false,
        addDate: val.addtime,
      }
      arr.push(obj);
    }
    setTableData(arr);
    setTotal(res.data.data.total);
    setTypeOption(type);
  }

  const columns = [
    {
      title: "类型",
      dataIndex: "type",
      render: (text, record) => {
        return (
          text ? <div>{text}</div >
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
      filterMultiple: false,
      filters: typeOption
    },
    {
      title: "微信ID",
      dataIndex: "wxid",
      render: (text, record) => {
        return (
          text ? <div>{text}</div >
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "姓名",
      dataIndex: "name",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "手机",
      dataIndex: "phone",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    // {
    //   title: "启用",
    //   dataIndex: "isEnable",
    //   render: (text, record) => {
    //     return (
    //       <Switch checked={record.isEnable} onChange={(e) => {
    //         setVehicleManagerState(record.idx, e);
    //       }} />
    //     )
    //   },
    // },
    {
      title: "添加日期",
      dataIndex: "addDate",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "操作",
      render: (text, record) => {
        return <div>
          <span className='pointer' style={{ color: colorPrimary }} onClick={() => {
            let info = { ...drawer };
            info.isOpen = true;
            info.id = record.id;
            info.title = '编辑';
            setDrawer(info);
          }}> 编辑</span>

          <Divider type="vertical" />
          <span className='pointer' style={{ color: colorPrimary }} onClick={() => {
            confirm({
              title: '是否删除?',
              icon: <ExclamationCircleFilled />,
              content: '该学生的车辆将删除，请谨慎操作!!!',
              okText: '删除',
              okType: "danger",
              cancelText: "容我想想~",
              async onOk() {
                await delVehicleManager(record.id);
              },
              onCancel() { },
            });
          }}>删除</span>
        </div>
      },
    },
  ];

  //删除
  const delVehicleManager = async (id) => {
    setLoading(true);
    let res = await setVehicleManagerStateFun({
      id: id,
      type: 2
    });
    setLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    message.success('删除成功');
    if (tableData.length === 1 && siftData.page > 1) {
      let sift = { ...siftData };
      sift.page = sift.page - 1;
      getTableData(sift);
      setSiftData(sift);
    } else {
      getTableData(siftData);
    }
  }

  //修改
  const setVehicleManagerState = async (idx, state) => {
    let res = await setVehicleManagerStateFun({
      id: tableData[idx].id,
      type: 1,
      is_enabled: state ? 1 : 0
    });
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    let arr = [...tableData];
    arr[idx].isEnable = state;
    setTableData(arr);
  }

  //关闭抽屉
  const closeDrawer = (refresh) => {
    let info = { ...drawer };
    info.isOpen = false;
    setDrawer(info);
    if (refresh) {
      getTableData(siftData)
    }
  }

  useEffect(() => {
    getTableData(siftData)
    // eslint-disable-next-line
  }, [])

  return (
    <Card className='mar24'>
      <div className='tr pdRL8 marB16'>
        <Button icon={<PlusOutlined />} type="primary" onClick={() => {
          let info = { ...drawer };
          info.isOpen = true;
          info.id = '';
          info.title = '添加';
          setDrawer(info);
        }}>添加</Button>
      </div>
      <Table rowKey='id' loading={loading} columns={columns} dataSource={tableData} pagination={false}
        onChange={(pagination, filters, sorter) => {
          let sift = { ...siftData };
          sift.page = 1;
          for (let key in filters) {
            if (filters[key]) {
              sift[key] = filters[key][0]
            } else {
              sift[key] = '';
            }
          }
          setSiftData({ ...sift });
          getTableData(sift);
        }}
      />

      {/* 页脚 */}
      <div className='pdT16 tr'>
        <Pagination current={siftData.page} pageSize={siftData.limit} total={total} showTotal={total => `共 ${total} 条数据`}
          showSizeChanger={true}
          onChange={
            (current, pageSize) => { //选择当前页后执行
              let sift = { ...siftData }
              sift.page = current;
              if (sift.limit !== pageSize) {
                sift.page = 1;
                sift.limit = pageSize;
              }
              setSiftData({ ...sift })
              getTableData(sift)
            }
          }
        />
      </div>

      <Drawer title={drawer.title} width={256} placement="right" onClose={() => { closeDrawer() }} open={drawer.isOpen}
        destroyOnClose={true}
        extra={
          <Space>
            <Button type="primary" onClick={() => {
              setVehicleManagerRef.current.submit();
            }}>
              提交
            </Button>
          </Space>
        }
      >
        <SetVehicleManager onRef={setVehicleManagerRef} id={drawer.id} type={typeOption} close={closeDrawer}></SetVehicleManager>
      </Drawer>

    </Card>
  )
}
export default VehicleManager;