// 登录页面
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Input, App, Checkbox, theme } from 'antd';
import { UserOutlined, UnlockOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import './index.css'
import logo_text from '@/common/img/logo_text.svg'
import { loginFun } from '@/server/login'
// import store from '@/store';



function Login() {
  const navigate = useNavigate();

  const { message } = App.useApp();
  const {
    token: { colorText, colorPrimary, colorTextTertiary },
  } = theme.useToken(); //获取主题颜色

  const [btnLoad, setBtnLoad] = useState(false);//按钮加载事件
  const [form] = Form.useForm();
  const [remember, setRemember] = useState(true);

  //提交
  const onFinish = async (e) => {
    setBtnLoad(true);
    let res = await loginFun({
      username: e.username,
      password: e.password,
      code: e.code
    });
    setBtnLoad(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '请求失败，请稍后重试');
      return
    }
    message.success('登录成功');
    if (remember) {
      localStorage.setItem('vehicle_management_username', e.username);
    }
    localStorage.setItem('vehicle_management_IsLogin', true);
    // localStorage.setItem('vehicle_management_token', 123321);
    localStorage.setItem('vehicle_management_menuKey', 'electric_vehicle_audit')
    navigate('/electric_vehicle_audit');

  }

  //忘记密码
  const forget = () => {

  }

  useEffect(() => {
    localStorage.removeItem('vehicle_management_IsLogin');
    localStorage.removeItem('vehicle_management_menuKey');
    localStorage.removeItem('vehicle_management_token');
    let username = localStorage.removeItem('vehicle_management_username') || '';
    localStorage.clear();
    form.setFieldsValue({
      username: username
    });
    //eslint-disable-next-line
  }, [])

  return (
    <div className='main_div'>
      <div className='body_div'>
        <img src={logo_text} alt='logo' style={{ marginBottom: 107 }}></img>
        <div style={{ width: '328px' }}>
          <Form name="basic"
            onFinish={onFinish}
            form={form}
            autoComplete="off"
          >
            <Form.Item name="username"
              rules={[{ required: true, message: '请输入用户名', },]}
            >
              <Input size="large" prefix={<UserOutlined style={{ color: '#1890FF', height: '24px' }} />} />
            </Form.Item>

            <Form.Item name="password"
              rules={[{ required: true, message: '请输入密码', },]}
            >
              <Input.Password size="large" prefix={<UnlockOutlined style={{ color: '#1890FF', height: '24px' }} />} />
            </Form.Item>
            <div className='flex_row justify_between align_center marB24'>
              <Checkbox checked={remember} onChange={(e) => {
                setRemember(e.target.checked);
              }}>记住此账号</Checkbox>

              {/* eslint-disable-next-line */}
              {/* <a>忘记密码 ?</a> */}
            </div>
            <Button size="large" type="primary" htmlType="submit" className='wid100 bc_primary' loading={btnLoad} >
              登录
            </Button>
          </Form>
        </div>
      </div>
      <div className="tc marB10" style={{ color: colorTextTertiary }}>
        <font className="fs14 marR40">有舟科技</font>
        <font className="fs14">Ant Design</font>
      </div>
      <div className="tc lh22 marB27" style={{ color: colorTextTertiary }}>
        Copyright ©2023 Produced by You Zhou Group Technology Department
      </div>
    </div>

  )
}
export default Login