// 查询
import { useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { Card, Input, Space, Pagination, theme, Button, Modal, Tag, Table, Empty, Spin } from 'antd'
import { SearchOutlined, FieldTimeOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import UserInfo from '../electric_vehicle_audit/user_info';
import { getUserListAPI } from '@/server/query.js';
import { debounce } from '@/common/js/common.js';


function Query () {
  const navigate = useNavigate();//路由跳转
  const location = useLocation();//路由接受参数
  const {
    token: { colorTextQuaternary, colorText },
  } = theme.useToken(); //获取主题颜色

  const [loading, setLoading] = useState(false)//加载中···
  const [tableData, setTableData] = useState([])//表格数据

  const [searchList, setSearchList] = useState({//查询列表
    page: 1,
    limit: 10,
    total: 0,
    car_number: '',//车牌
  })

  // 根据查询条件获取数据
  const getAllQuery = async (data) => {
    setLoading(true);
    let res = await getUserListAPI(data)
    setLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    let sea = searchList
    sea.total = res.data?.data?.total || 0
    setTableData([...res.data.data.items || []])
  }


  const [userInfoModal, setUserInfoModal] = useState({//用户信息弹窗
    isOpen: false,
    id: '',
  });


  // 表头
  const columns = [
    {
      title: "ID",
      dataIndex: "code_id",
      render: (text, record) => {
        return (
          text
            ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "类型",
      dataIndex: "role",
      render: (text, record) => {
        return (
          text
            ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "姓名",
      dataIndex: "name",
      render: (text, record) => {
        return (
          text
            ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "手机",
      dataIndex: "phone",
      render: (text, record) => {
        return (
          text
            ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "学院/单位",
      dataIndex: "user_unit",
      render: (text, record) => {
        return (
          text
            ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "有效期",
      dataIndex: "expiry_time",
      render: (text, record) => {
        return (
          text
            ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "提交日期",
      dataIndex: "submit_time",
      render: (text, record) => {
        return (
          text
            ? <div className='opa45'>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "审核日期",
      dataIndex: "examine_time",
      render: (text, record) => {
        return (
          text
            ? <div className='opa45'>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "状态",
      dataIndex: "is_approved",
      render: (text, record) => {
        switch (text) {
          // 初审
          case 1:
            return (
              <Tag icon={<FieldTimeOutlined />} color="warning">
                {record.is_approved_txt}
              </Tag>
            )
          // 复审
          case 2:
            return (
              <Tag icon={<FieldTimeOutlined />} color="warning">
                {record.is_approved_txt}
              </Tag>
            )
          // 驳回
          case 3:
            return (
              <Tag icon={<CloseCircleOutlined />} color="error">
                {record.is_approved_txt}
              </Tag>
            )
          // 通过
          case 4:
            return (
              <Tag icon={<CheckCircleOutlined />} color="success">
                {record.is_approved_txt}
              </Tag>
            )
          default:
            return <div style={{ color: colorTextQuaternary }}>-</div>
        }

      },
    },
    {
      title: "操作",
      dataIndex: "code_number",
      render: (text, record) => {
        return (
          <span
            className='pointer'
            style={{ color: '#1677FF' }}
            onClick={() => {
              let use = userInfoModal
              use.isOpen = true;
              use.id = record.wId;
              setUserInfoModal({ ...use });
            }}
          >
            详情
          </span>
        )
      },
    },
  ]


  useEffect(() => {

    // eslint-disable-next-line
  }, [])

  return (
    <div className='mar24'>
      {/* 搜索条件 */}
      <Card className='marB24'>
        <Space>
          {/* 车牌查询 */}
          <Input
            allowClear
            className='w144'
            placeholder='车牌'
            onChange={(e) => {
              let sea = searchList
              sea.car_number = e.target.value
              setSearchList({ ...sea })
            }}
          />


          <Button
            icon={<SearchOutlined />}
            type='primary'
            onClick={debounce(() => { getAllQuery(searchList) }, 500)}
          >
            查询
          </Button>

        </Space>
      </Card>

      {/* 条件展示 */}
      <Card>
        <Spin spinning={loading}>
          {
            tableData.length > 0
              ? (
                <>
                  <Table
                    rowKey='id'
                    loading={loading}
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                  />

                  <div className='pdT16 tr'>
                    <Pagination current={searchList.page} pageSize={searchList.limit} total={searchList.total} showTotal={total => `共 ${total} 条数据`}
                      showSizeChanger={true}
                      onChange={
                        (current, pageSize) => { //选择当前页后执行
                          let sea = searchList
                          sea.page = current
                          if (sea.limit !== pageSize) {
                            sea.page = 1;
                            sea.limit = pageSize;
                          }
                          setSearchList({ ...sea })
                          getAllQuery(sea)
                        }
                      }
                    />
                  </div>
                </>
              )
              : (
                <Empty />
              )
          }


        </Spin>
      </Card>

      {/* 用户信息 */}
      <Modal title="" width={576} open={userInfoModal.isOpen} destroyOnClose={true} closable={false}
        styles={{ content: { padding: 16 } }}
        onCancel={() => {
          let use = userInfoModal
          use.isOpen = false
          setUserInfoModal({ ...use })
        }}
        footer={null}
      >
        <UserInfo id={userInfoModal.id}></UserInfo>
      </Modal>
    </div>
  )
}
export default Query;