//二维码
import React, { useState, useEffect, useRef } from 'react'
import { Card, theme, Button, Table, InputNumber, Form, Select, Drawer, Spin, Space, Pagination, QRCode, Modal, DatePicker, App } from 'antd'
import { PlusOutlined, QrcodeOutlined, ExportOutlined } from '@ant-design/icons'
import { getQRCodeFun, setQRCodeNumFun, getAllQRCodeFun } from '@/server/qrcode.js'
import dayjs from 'dayjs';
import JSZip from 'jszip'
import FileSaver from 'file-saver'

function CarQRCode () {
  const { message } = App.useApp();
  const {
    token: { colorText, colorTextQuaternary, colorPrimary },
  } = theme.useToken(); //获取主题颜色

  const time1 = useRef()

  const [drawerForm] = Form.useForm();//抽屉表单

  const [loading, setLoding] = useState(false)//加载中···
  const [tableData, setTableData] = useState([])//列表数据
  const [roleDictionary, setRoleDictionary] = useState([])//角色 字典

  const [userType, setUserType] = useState('');//当前选择的用户类型
  const [ageLimitOption, setAgeLimitOption] = useState([]);//有效年限选项
  const [carTypeOption, setCarTypeOption] = useState([]);//车辆类型选项
  const [chooseCarType, setChooseCarType] = useState(null);//选择的车辆类型

  const [searchList, setSearchList] = useState({//搜索列表
    page: 1,
    limit: 10,
    total: 0,
  });

  const [generateDrawer, setGenerateDrawer] = useState({//生成  抽屉
    isOpen: false,
    loading: false,
  });

  const [exportModalInfo, setExportModalInfo] = useState({ //导出弹窗信息
    isOpen: false,
    date: null,//导出日期
  });
  const [exportLoading, setExportLoading] = useState(false);//导出加载
  const [exportData, setExportData] = useState([]);//导出的数据

  const getTableData = async (data) => {//获取所有数据
    setLoding(true)
    let res = await getQRCodeFun({
      page: data.page,
      perpage: data.limit,
    });
    console.log('res', res);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      setLoding(false);
      return;
    }

    // 车辆字典
    let carArr = [];
    res.data.sjzd.cllx.forEach(item => {
      let obj = {
        value: Number(item.xmbm),
        label: item.xmmc,
      }
      carArr.push(obj);
    });
    setCarTypeOption([...carArr]);

    // 有效年限
    let ageLimitArr = [];
    res.data.sjzd.yxqnx.forEach(item => {
      let obj = {
        value: Number(item.xmbm),
        label: item.xmmc,
      }
      ageLimitArr.push(obj);
    });
    setAgeLimitOption([...ageLimitArr]);

    // 角色 字典
    let rolArr = []
    res.data.sjzd.yhjb.forEach(item => {
      let obj = {
        value: Number(item.xmbm),
        label: item.xmmc,
      }
      rolArr.push(obj)
    });

    let sea = searchList;
    sea.total = res.data.data.total;
    setSearchList({ ...sea });
    setRoleDictionary([...rolArr])
    setTableData([...res.data.data.items] || []);
    setLoding(false);
  }

  //获取满足条件的二维码
  const getAllQRCode = async (date) => {
    setExportLoading(true);
    let res = await getAllQRCodeFun({
      addtime: date
    });
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      setExportLoading(false);
      return;
    }
    let list = res.data.data;
    if (list.length === 0) {
      message.warning('当前日期没有可以导出的二维码');
      setExportLoading(false);
      return;
    }
    setExportData(list);

    clearInterval(time1.current);//防止误点 导致的多次time1
    time1.current = setInterval(() => {
      let dom = document.getElementById('qrcode').querySelectorAll('svg');
      if (dom.length === list.length) {
        clearInterval(time1.current);
        const zip = new JSZip();
        for (let i = 0; i < dom.length; i++) {

          if (dom[i].getElementsByTagName('*').length > 1 && dom[i].firstElementChild && dom[i].firstElementChild.tagName.toLowerCase() === 'path') {
            // 如果是<path>元素，则删除它
            dom[i].removeChild(dom[i].firstElementChild);
          }
          let svgDom = '<svg xmlns="http://www.w3.org/2000/svg" height="160" width="160" viewBox="0 0 21 21">' + dom[i].innerHTML + '</svg>';
          console.log('svgDom', svgDom);
          // 往文件夹中，添加每张图片数据
          let fileName = list[i].code_number + '-' + list[i].car_type + '-' + list[i].role_type_txt;
          if (list[i].expiry) {
            fileName = fileName + '-' + list[i].expiry;
          }
          zip.file(fileName + '.svg', svgDom)
        }
        zip.generateAsync({ type: 'blob' }).then(content => {
          FileSaver.saveAs(content, dayjs(exportModalInfo.date).format('YYYY-MM-DD') + '二维码.zip')
        });
        setExportLoading(false);
        message.success('导出成功');
      }
    }, 1000);
  }

  const downloadQRCode = (id, fileName) => {  //根据id下载二维码
    const svg = document.getElementById(id)?.querySelector('svg');
    console.log('svg', svg);
    if (svg.getElementsByTagName('*').length > 1 && svg.firstElementChild && svg.firstElementChild.tagName.toLowerCase() === 'path') {
      // 如果是<path>元素，则删除它
      svg.removeChild(svg.firstElementChild);
      console.log('svg.firstElementChild', svg.firstElementChild);
    }
    if (svg) {
      let a = document.createElement('a');
      const s = new XMLSerializer().serializeToString(svg);
      a.href = `data:image/svg+xml;base64,${window.btoa(s)}`;
      a.download = fileName
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

  };

  const onFinish = async (e) => {//提交生成数据
    let gen = generateDrawer
    gen.loading = true
    setGenerateDrawer({ ...gen })
    let res = await setQRCodeNumFun({
      role: e.type,
      number: e.number,
      car_type: e.carType,
      expiry: e.ageLimit,
    })
    console.log('res', res);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      gen.loading = false
      setGenerateDrawer({ ...gen })
    }

    gen.isOpen = false
    gen.loading = false
    message.success(res.msg || '生成二维码成功');
    setGenerateDrawer({ ...gen })
    getTableData(searchList);
    drawerForm.resetFields();
  }

  const columns = [//表头
    {
      title: "ID",
      dataIndex: "code_number",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "二维码",
      width: 100,
      align: 'center',
      render: (text, record) => {
        return (
          record.id
            ?
            <div>
              <div
                className='pointer' style={{ color: colorPrimary }}
                onClick={() => {
                  downloadQRCode('QRCode' + record.id, record.code_number);
                }}>
                <QrcodeOutlined />
              </div>
              <div id={'QRCode' + record.id} style={{ display: 'none' }}>
                <QRCode type="svg" value={record.code_number.toString()} bordered={false} />
              </div>
            </div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "类型",
      dataIndex: "role_type_txt",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "有效年限",
      dataIndex: "expiry",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "车辆类型",
      dataIndex: "car_type",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "状态",
      dataIndex: "is_use",
      render: (text, record) => {
        return (
          <div>{Number(text) ? '绑定' : <div style={{ color: colorTextQuaternary }}>-</div>}</div>
        )
      },
    },
    {
      title: "生成日期",
      dataIndex: "addtime",
      render: (text, record) => {
        return (<div style={{ color: colorTextQuaternary }}>{text ? text : '-'}</div>
        )
      },
    },
  ]

  useEffect(() => {
    getTableData(searchList);
    return () => {
      clearInterval(time1.current);
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Card className='mar24'>
      <div className='flex_row align_center marB16 pdRL8'>
        <div className='flex_auto'></div>
        <Button className='marR16'
          type='primary'
          icon={<ExportOutlined />}
          onClick={() => {
            let info = { ...exportModalInfo };
            info.isOpen = true;
            setExportModalInfo(info);
          }}
        >
          批量导出
        </Button>
        <Button
          type='primary'
          icon={<PlusOutlined />}
          onClick={() => {
            let gen = generateDrawer;
            gen.isOpen = true;
            setGenerateDrawer({ ...gen });
            setUserType('');
          }}
        >
          生成
        </Button>
      </div>

      <Table rowKey='id' loading={loading} columns={columns} dataSource={tableData} pagination={false} />
      {/* 页脚 */}
      <div className='pdT16 tr'>
        <Pagination current={searchList.page} pageSize={searchList.limit} total={searchList.total} showTotal={total => `共 ${total} 条数据`}
          showSizeChanger={true}
          onChange={
            (current, pageSize) => { //选择当前页后执行
              let sea = searchList
              sea.page = current
              if (sea.limit !== pageSize) {
                sea.page = 1;
                sea.limit = pageSize;
              }
              setSearchList({ ...sea })
              getTableData(sea)
            }
          }
        />
      </div>

      <Drawer
        width={256}
        title='生成'
        open={generateDrawer.isOpen}
        placement='right'
        destroyOnClose={true}
        onClose={() => {
          drawerForm.resetFields()
          let gen = generateDrawer;
          gen.isOpen = false;
          setGenerateDrawer({ ...gen });
          setUserType('');
          drawerForm.resetFields();
        }}
        extra={
          <Space>
            <Button type="primary" onClick={() => {
              document.getElementById('submitForm').click()
            }}>
              确定
            </Button>
          </Space>
        }
      >
        <Spin spinning={generateDrawer.loading}>
          <Form
            form={drawerForm}
            layout='vertical'
            onFinish={onFinish}
          >
            <Form.Item
              name='carType'
              label='车辆类型'
              rules={[{ required: true, message: '请选择车辆类型', }]}
            >
              <Select options={carTypeOption} onChange={(e) => {
                setChooseCarType(e);
                drawerForm.setFieldsValue({
                  type: null,
                  ageLimit: null
                })
              }} />
            </Form.Item>

            <Form.Item
              hidden={!chooseCarType || chooseCarType === 2 ? true : false}
              name='type' label='类型'
              rules={[{ required: chooseCarType !== 2 ? true : false, message: '请选择类型', }]}
            >
              <Select
                onChange={(e) => {
                  setUserType(Number(e));
                }}
                options={roleDictionary}
              />
            </Form.Item>

            <Form.Item
              hidden={userType !== 3}
              name='ageLimit'
              label='有效年限'
              rules={[{ required: userType === 3, message: '请选择有效年限', }]}
            >
              <Select options={ageLimitOption} />
            </Form.Item>

            <Form.Item
              name='number'
              label='数量'
              rules={[{ required: true, message: '请输入数量', }]}
            >
              <InputNumber className='wid100' placeholder='-' />
            </Form.Item>

            <Form.Item style={{ display: 'none' }}>
              <Button id='submitForm' type="primary" htmlType="submit" />
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>

      <Modal width={280} title="批量导出" open={exportModalInfo.isOpen} confirmLoading={exportLoading}
        onOk={() => {
          if (!exportModalInfo.date) {
            message.warning('请选择导出日期');
            return;
          }
          let date = dayjs(exportModalInfo.date).format('YYYY-MM-DD');
          getAllQRCode(date);
        }} onCancel={() => {
          let info = { ...exportModalInfo };
          info.isOpen = false;
          setExportModalInfo(info);
        }}>
        <DatePicker className='wid100' value={exportModalInfo.date} onChange={(e) => {
          let info = { ...exportModalInfo };
          info.date = e;
          setExportModalInfo(info);
        }} />
      </Modal>

      <div id="qrcode" style={{ display: 'none' }}>
        {
          exportData.length > 0 ? exportData.map((item, idx) => {
            return <QRCode type="svg" value={item.code_number.toString()} bordered={false} key={idx} />
          }) : ''
        }
      </div>
    </Card>
  )
}
export default CarQRCode;