import { createStore } from 'redux'
// createStore 创建仓库

// import {useDispatch} from 'react-redux'
// useSelector 获取仓库中的某个值
// 用法 useSelector(state => console.log)
// useDispatch 更新数据
// 用法 const dispatch = useDispatch();dispatch({type:'',})


// 仓库数据
const default_val = {
    token:'',
    value: '',
    num:0,
}

// 改变数据的方法
function counterReducer(state = default_val, action) {
    switch (action.type) {
        case 'token':
            state.token = action.value;
            return {...state}
        default:
            return {...state}
    }
}

export default createStore(counterReducer)