// 统计
import { useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { Card, theme, DatePicker, Select, Empty, Badge, Descriptions } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { getUnitNumAPI, getPieChartAPI } from '@/server/statistics.js'
import * as echarts from 'echarts';
import dayjs from 'dayjs';
import './index.css';


function Statistics() {
  const navigate = useNavigate();//路由跳转
  const location = useLocation();//路由接受参数
  const {
    token: { colorTextPlaceholder },
  } = theme.useToken(); //获取主题颜色

  const [loading, setLoading] = useState(false)//加载中···

  const [windowSize, setWindowSize] = useState({
    width: Number(window.innerWidth) - 387,
    height: Number(window.innerHeight) - 112,
  });

  const [userTypeItems, setUserTypeItems] = useState([]);

  const [gradeItems, setGradeItems] = useState([]);

  const [getCarNumByUnit, setGetCarNumByUnit] = useState({//根据单位获取车辆办理数量
    unitData: [],//单位数据
    unitDictionary: [],//单位 字典
    curUnitType: null,//当前选择的单位类别

    carDayData: [],//每日办理车辆数据
    curCarDay: "",//当前选择的车辆办理的日期
  })

  // 获取 单位类别 办理数量
  const getUnitNum = async (type, value) => {
    let carn = getCarNumByUnit
    let obj = {
      month: type === 1 ? value : getCarNumByUnit.curCarDay,
      user_unit: type === 2 ? value : getCarNumByUnit.curUnitType,
    }

    let res = await getUnitNumAPI(obj)
    console.log('res', res);
    if (res.ret !== 200) { message.warning(res.msg || '数据跑丢了~'); }
    //单位 字典
    let utype = res.data.sjzd.unit.map(res => {
      return ({
        label: res.xmmc,
        value: Number(res.xmbm)
      })
    })
    let user = res.data.role.map((item, idx) => {
      return {
        key: idx,
        label: item.role,
        children: item.count,
        labelStyle: { width: 100, color: 'black' },
        contentStyle: { width: 64, textAlign: 'right', color: Number(item.count) > 0 ? 'black' : colorTextPlaceholder }
      }
    });
    let grade = res.data.grades.map((item, idx) => {
      return {
        key: idx,
        label: item.grade.slice(2),
        children: item.count,
        labelStyle: { width: 100, color: 'black' },
        contentStyle: { width: 64, textAlign: 'right', color: Number(item.count) > 0 ? 'black' : colorTextPlaceholder }
      }
    });
    setUserTypeItems(user);
    setGradeItems(grade);

    carn.unitDictionary = utype
    carn.curUnitType = res.data.user_unit
    carn.unitData = res.data.data
    carn.carDayData = res.data.monthdata
    carn.curCarDay = res.data.month
    setGetCarNumByUnit({ ...carn })
    createcarBarChart(carn.carDayData)
  }

  // 获取 饼图  数据
  const getPieChart = async () => {
    let obj = {}
    let res = await getPieChartAPI(obj)
    if (res.ret !== 200) { message.warning(res.msg || '数据跑丢了~'); }
    // setValidityPeriodData([...res.data.grade])
    // setGradeDatasetValidityPeriodData([...res.data.expiry])
    creactPieChartByGrade(res.data.data.grade, windowSize)
    creactPieChartByValidityPeriod(res.data.data.expiry, windowSize)
  }

  // 创建 办理车辆 柱状图
  const createcarBarChart = (data) => {
    let myChart = echarts.init(document.getElementById('carBarChart'));
    window.addEventListener('resize', function () {
      myChart.resize();
    });


    // 指定图表的配置项和数据
    let option = {
      xAxis: {
        data: data.map((res) => { return `${res.day.substring(8, 10)}日` }),
        axisLine: {
          lineStyle: {
            color: 'rgba(229,229,229)' // 设置线的颜色为透明，相当于去除线
          }
        },



        axisLabel: {
          textStyle: {
            color: 'rgba(0,0,0,0.4)'// 坐标轴上的文字颜色
          }
        }
      },
      yAxis: {
        splitLine: {
          show: true, // 控制分割线是否显示，默认为 true
          lineStyle: {
            type: 'dashed', // 设置分割线的样式为虚线
            color: 'rgba(0,0,0,0.1)', // 设置分割线的颜色
          }
        },
        axisLabel: {
          textStyle: {
            color: 'rgba(0,0,0,0.4)'// 坐标轴上的文字颜色
          }
        }
      },
      series: [
        {
          // name: '车辆数量/日',
          type: 'bar',
          data: data.map((res) => { return res.count }),
          itemStyle: { color: 'rgba(35,137,255)', },
        }
      ],
      tooltip: {
        trigger: 'item'
      },
      // 设置 柱状图 四周边距
      grid: {
        left: 0,
        right: 0,
        top: 16,
        bottom: 0,
        containLabel: true,
      }
    };

    // 使用刚指定的配置项和数据显示图表。
    myChart.setOption(option);
  }

  // 创建 按 年级  车辆办理数量 扇形图
  const creactPieChartByGrade = (data) => {
    let myChart = echarts.init(document.getElementById('gradePieChart'));
    window.addEventListener('resize', function () {
      myChart.resize();
    });
    let option = {
      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          // name: '年级车辆办理数据',
          type: 'pie',
          radius: ['50%', '80%'],
          data: data.map(res => { return ({ value: res.count, name: res.grade }) }),
          // 设置 饼图 外部文字显示
          label: {
            show: true,
            position: 'outside',
            textStyle: {
              color: 'rgba(0,0,0,0.6)'
            },
            formatter: (params) => {
              return params.name + '：' + params.value
            }
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          // 设置 饼图 颜色 顺时针
          color: ['#6AC73B', 'rgba(241,142,84)', 'rgba(13,204,204)', 'rgba(35,137,255)']
        }
      ]
    };

    // 使用刚指定的配置项和数据显示图表。
    myChart.setOption(option);
  }

  // 创建 按 有效期  车辆办理数量 扇形图
  const creactPieChartByValidityPeriod = (data) => {
    let myChart = echarts.init(document.getElementById('validityPeriodPieChart'));
    window.addEventListener('resize', function () {
      myChart.resize();
    });

    // 指定图表的配置项和数据
    let option = {
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          // name: '年限车辆办理数据',
          type: 'pie',
          radius: ['50%', '80%'],
          data: data.map(res => { return ({ value: res.count, name: res.expiry }) }),
          label: {
            show: true,
            position: 'outside',
            textStyle: {
              color: 'rgba(0,0,0,0.6)'
            },
            formatter: (params) => {
              return params.name + '：' + params.value
            }
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          color: ['#6AC73B', 'rgba(241,142,84)', 'rgba(13,204,204)', 'rgba(35,137,255)', 'rgba(127,107,255)', 'rgba(104,199,56)',]
        }
      ]
    };

    // 使用刚指定的配置项和数据显示图表。
    myChart.setOption(option);
  }

  useEffect(() => {
    getUnitNum()
    getPieChart()
    // eslint-disable-next-line
  }, [])

  // 监听页面大小
  useEffect(() => {
    // 设置事件监听器以监听窗口大小变化
    const handleResize = () => {
      setWindowSize({
        width: Number(window.innerWidth) - 387,
        height: Number(window.innerHeight) - 112,
      });
    };

    window.addEventListener('resize', handleResize);

    // 清理函数，在组件卸载或effect重新运行之前移除事件监听器
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // 空数组作为依赖项，意味着这个effect只会在组件挂载时运行一次

  return (
    <div className='mar24'>
      <Card
        title={
          <div className='flex jcsb align_center'>
            <div className='flex jcl align_center'>
              <div className='marR16'>每日办理车辆数量</div>
              <DatePicker
                allowClear={false}
                value={dayjs(getCarNumByUnit.curCarDay, 'YYYY-MM')}
                picker='month'
                onChange={(e) => {
                  let gcn = getCarNumByUnit
                  gcn.curCarDay = dayjs(e).format('YYYY-MM');
                  setGetCarNumByUnit({ ...gcn })
                  getUnitNum(1, gcn.curCarDay)
                }}
              />
            </div>

            <div className='flex jcr align_center w330'>
              <div className='marR16 ant-card-head-title w120'>分类单位数据量</div>
              <Select
                className='w190'
                value={Number(getCarNumByUnit.curUnitType)}
                options={getCarNumByUnit.unitDictionary}
                onChange={(e) => {
                  let carn = getCarNumByUnit
                  carn.curUnitType = Number(e)
                  setGetCarNumByUnit({ ...carn })
                  getUnitNum(2, carn.curUnitType)
                }}
              />
            </div>
          </div>
        }
        className='marB24 hidden'
      >
        <div className='flex jcsb'>
          {/* 每日办理车辆数量 */}
          <div className='marR24'>
            <div id="carBarChart" style={{ width: 'calc(100vw - 296px - 24px - 330px)', height: `300px`, display: getCarNumByUnit.carDayData.length > 0 ? `` : `none` }} />
            <div className='h300 flex jcc align_center' style={{ width: 'calc(100vw - 296px - 24px - 330px)', display: getCarNumByUnit.carDayData.length > 0 ? `none` : `` }}>
              <Empty />
            </div>
          </div>

          {/* 分类单位数据量 */}
          <div className='w328 flex_col' style={{ minWidth: '328px' }}>
            <div>
              {
                (getCarNumByUnit.unitData || []).map((res, index) => {
                  return (
                    <div key={index} className='flex jcsb marT16'>
                      <div>
                        <div className='acss-1g84m4g'>{index + 1}</div>
                        {res.car_type}
                      </div>
                      <div style={{ color: Number(res.count) > 0 ? 'black' : colorTextPlaceholder }}>{res.count}</div>
                    </div>
                  )
                })
              }
            </div>

            <div className='marT12' style={{ display: userTypeItems.length > 0 ? '' : 'none' }}>
              <Descriptions column={2} bordered items={userTypeItems} size='small' />
            </div>
            <div className='marT12' style={{ display: gradeItems.length > 0 ? '' : 'none' }}>
              <Descriptions column={2} bordered items={gradeItems} size='small' />
            </div>

          </div>
        </div>
      </Card>

      {/* 饼图 */}
      <div className='flex'>
        {/* 按  年级  分类 */}
        <Card
          className='hidden'
          style={{ width: 'calc(50% - 12px )', }}
          title='年级车辆办理数据'
        >
          {/* <div id="gradePieChart" style={{ width: `${windowSize.width / 2}px`, height: '340px' }} /> */}
          <div id="gradePieChart" style={{ width: '100%', height: '340px' }} />
        </Card>

        <div className='w24'></div>

        {/* 按  有效期  分类 */}
        <Card
          className='hidden'
          style={{ width: 'calc(50% - 12px )', }}
          title='年限车辆办理数据'
        >
          <div id="validityPeriodPieChart" style={{ width: '100%', height: '340px' }} />
        </Card>

      </div>

    </div>
  )
}
export default Statistics;