// 封装通用的ajax函数
// 引入axios和qs
import axios from 'axios'
import Qs from 'qs'
import { message } from 'antd'
import { startPath, basicPath } from '@/router/config'

// axios.defaults.withCredentials = true

// const url = 'https://test.9zhouzhineng.com' //测试
// const url = 'http://192.168.101.11:88' //本地
// export const baseUrl = '/api' //本地
// export const baseUrl = '/' //本地
export const baseUrl = 'https://sdcl.jlmdrive.com' //本地
axios.defaults.baseURL = baseUrl;


// API请求异常统一报错提示
export function API_FAILED(msg) {
  message.warning(msg || '数据跑丢了~', 1.5);
}

// 添加请求拦截器，在请求头中加token,Cookie
axios.interceptors.request.use(
  (config) => {
    // if (local.get('sfh')) {
    //   // this.$router.push('/home');
    // }
    // if (localStorage.getItem('Authorization')) {
    //   config.headers.Authorization = localStorage.getItem('Authorization');
    // }
    // if (localStorage.getItem('Cookies')) {
    //   config.headers.Cookies = localStorage.getItem('Cookies');
    // }
    return config;
  }, error => {
    return Promise.reject(error);
  });

// 这里拦截错误
axios.interceptors.response.use(
  response => {
    if (response.data && !response.data.ret) {
      API_FAILED(null);
      return
    }
    else if (response.data && (response.data.ret === -1 || response.data.ret === 406)) {
      API_FAILED(response.data.msg || null);
      localStorage.removeItem('vehicle_management_IsLogin');
      setTimeout(() => {
        window.location.replace(basicPath + startPath);
      }, 1500)
    } else {
      return response
    }
  }, error => {
    return Promise.reject(error.response);
  }
);

// 通用的get请求 和 post请求
const req = {
  get(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params,
        })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            return
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  post(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, Qs.stringify(params))
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            return
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  file(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          if (response && response.data) {
            resolve(response.data);
          } else {
            return
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  //拼接字符串
  SplicString(list) {
    let text = '';
    for (let i in list) {
      if (list[i]) {
        text = text + '&' + i + '=' + list[i]
      }
    }
    return text
  }
}

// 暴露请求对象
export default req