// 路由页面
import React, { useEffect } from 'react'
import { Routes, Route, Navigate } from "react-router-dom";
import { RouterConfig, startPath, StartComp, redirectPath } from './config'
import NotFound from '@/pages/not_found/not_found'

// 路由跳转拦截
function RequireAuth (component) {
  const isLogin = localStorage.getItem('vehicle_management_IsLogin');
  if (isLogin) {
    return (component)
  } else {
    return (<Navigate to={startPath} replace />) // 跳转到登录
  }
}

function RouterList () {
  return (
    <Routes>
      <Route path={startPath} element={<StartComp />} />
      <Route path='/' key='/' element={<Navigate to={redirectPath} replace />} />  {/* 主页重定向 */}
      {RouterConfig.map((item, idx) => {
        return (
          <Route path={item.path} key={idx} element={RequireAuth(<item.component />)} >
            {item.children && item.children.length > 0 ? (
              item.children.map((chd, cdx) => {
                return (
                  <Route path={chd.path} element={RequireAuth(<chd.component />)} key={cdx} />
                )
              })

            ) : ''}
          </Route>
        )
      })}
      < Route path="*" element={<NotFound />} />
    </Routes>

  )

}
export default RouterList