// 菜单页面
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Menu, App, theme } from 'antd';
import { MenuList } from './menu_list'


function MenuPage() {
  const navigate = useNavigate();//路由跳转
  const location = useLocation();//路由接受参数

  const { message } = App.useApp();

  const [current, setCurrent] = useState(); //默认选中菜单
  const [openMenu, setOpenMenu] = useState(); //初始选中菜单
  const vehicle_management_menuKey = localStorage.getItem('vehicle_management_menuKey');//当前选择的菜单

  const [menus, setMenus] = useState([]);//菜单路由

  // 点击菜单执行
  const onClick = async (e) => {
    setCurrent(e.key)
    if (e.key === '/login') {
      localStorage.removeItem('vehicle_management_IsLogin');
      localStorage.removeItem('vehicle_management_menuKey');
      localStorage.removeItem('vehicle_management_token');
      message.success('退出成功');
      setTimeout(() => {
        navigate(e.key);
      }, 1500);
    } else {
      if (e.key === location.pathname) {
        return;
      }
      localStorage.setItem('vehicle_management_menuKey', e.key)
      navigate(e.key);
    }

  }
  useEffect(() => {
    //获取所有的菜单路由 如果跳转的路由不在菜单内则不切换菜单默认选中
    let arr = [];
    for (let i = 0; i < MenuList.length; i++) {
      let val = MenuList[i];
      arr.push(val.key);
      if (val.children && val.children.length > 0) {
        for (let n = 0; n < val.children.length; n++) {
          let nal = val.children[n];
          arr.push(nal.key);
        }
      }
    }
    setMenus([...arr]);
    // if(vehicle_management_menuKey){
    //     navigate(vehicle_management_menuKey);
    // }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (menus.indexOf(location.pathname) >= 0) {
      setCurrent(location.pathname);
    }
    if (menus.length === 0) setCurrent(location.pathname);
    for (let i = 0; i < MenuList.length; i++) {
      let val_i = MenuList[i];
      if (val_i.children && val_i.children.length > 0) {
        for (let n = 0; n < val_i.children.length; n++) {
          let val_n = val_i.children[n];
          if (location.pathname === val_n.key) {
            setOpenMenu(val_n.parent)
          }
        }
      }
    }
    //eslint-disable-next-line
  }, [location.pathname])


  return (
    <div>
      <Menu
        style={{ height: '100%' }}
        selectedKeys={[current]}
        defaultOpenKeys={['video']}
        mode="inline"
        items={MenuList}
        onClick={onClick}
      />
    </div>
  )
}
export default MenuPage