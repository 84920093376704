// 统计
import req from './index'

//获取单位类别办理数量
export const getUnitNumAPI = (params) => {
  return req.post('/?service=Admin.Clgls.Stat_userunit', params)
}


//获取 饼图 数据
export const getPieChartAPI = (params) => {
  return req.post('/?service=Admin.Clgls.Stat_grade', params)
}


