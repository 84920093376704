//用户信息
//初审
import { useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { theme, Image, Spin, Descriptions, App } from 'antd'
import { handleAuditInfoFun } from '@/server/electric_vehicle_audit'

function UserInfo(props, ref) {
  useImperativeHandle(props.onRef, () => {
    return {

    };
  });
  const chooseId = props.id;//选择的id

  const { message } = App.useApp();
  const {
    token: { colorText, colorPrimary, colorFillQuaternary, colorTextTertiary },
  } = theme.useToken(); //获取主题颜色

  const [loading, setLoading] = useState(false)//加载中···
  const [userInfo, setUserInfo] = useState({ //用户信息
    name: '',
    phone: '',
    userType: '',
    userTypeValue: '',
    unit: '',
    code: '',
    carType: '',
    licensePlate: '',
    oneCardImg: '',
    carImg: '',
    side_pic: '',
    insuranceImg: '',
    drivingCardImg: [],
    driverCardImg: []
  });
  const [itemsOption, setItemsOption] = useState([]);//
  const items = [
    { key: '1', label: '手机', children: userInfo.phone },
    { key: '2', label: '人员类型', children: userInfo.userType },
    { key: '3', label: '学院/二级单位', children: userInfo.unit },
    { key: '4', label: userInfo.userTypeValue === 3 ? '学号' : userInfo.userTypeValue === 2 ? '工号' : '编号', children: userInfo.code },
    { key: '5', label: '车辆类型', children: userInfo.carType },
    { key: '6', label: '车牌号', children: userInfo.licensePlate },
  ]

  ///初始化
  const initialize = async () => {
    setLoading(true);
    let res = await handleAuditInfoFun({
      type: 0,
      wId: chooseId
    });
    console.log(res);
    setLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    let info = res.data.data && res.data.data.length > 0 ? res.data.data[0] : {};

    let drivers_license = [];
    let driving_license = [];
    if (info && info.drivers_license) {
      for (let i = 0; i < info.drivers_license.length; i++) {
        let val = info.drivers_license[i];
        drivers_license.push(val)
      }
    }
    if (info && info.driving_license) {
      for (let i = 0; i < info.driving_license.length; i++) {
        let val = info.driving_license[i];
        driving_license.push(val)
      }
    }

    //用户信息
    let obj = {
      name: info.name,
      grade: info.grade_txt,
      phone: info.phone,
      userType: info.role_txt,
      userTypeValue: Number(info.role),
      unit: info.user_unit_txt,
      code: info.swid,
      carType: info.car_type_txt,
      carTypeValue: Number(info.car_type),
      licensePlate: info.car_number,
      oneCardImg: info.campus_card,
      carImg: info.pic,
      side_pic: info.side_pic,
      insuranceImg: info.compulsory_insurance,
      drivingCardImg: driving_license,
      driverCardImg: drivers_license
    }
    setUserInfo(obj);

    //显示的参数
    let option = [
      { key: '1', label: '手机', children: obj.phone },
      { key: '2', label: '人员类型', children: obj.userType },
    ];
    if (obj.userTypeValue === 3) {
      option.push({ key: '3', label: '学院', children: obj.unit },)
      option.push({ key: '4', label: '学号', children: obj.code },)
    }
    if (obj.userTypeValue === 2) {
      option.push({ key: '3', label: '二级单位', children: obj.unit },)
      option.push({ key: '4', label: '工号', children: obj.code },)
    }
    if (obj.userTypeValue === 5) {
      option.push({ key: '3', label: '二级单位', children: obj.unit },)
      option.push({ key: '4', label: '编号', children: obj.code },)
    }
    if (obj.userTypeValue === 4) {
      option.push({ key: '3', label: '二级单位', children: obj.unit },)
    }
    option.push({ key: '5', label: '车辆类型', children: obj.carType });
    if (obj.carTypeValue !== 1) {
      option.push({ key: '6', label: '车牌号', children: obj.licensePlate });
    }
    setItemsOption(option);
  }


  useEffect(() => {
    initialize();
    // eslint-disable-next-line
  }, [])

  return (
    <div id='userInfo'>
      <Spin spinning={loading}>
        <div className='pdRL16 pdT16 radius4' style={{ backgroundColor: colorFillQuaternary }}>
          <Descriptions
            title={
              <div>
                <span className='fs20 fw600'>{userInfo.name}</span>
                <span className='fs14 fw400 opa45 marL8'>{userInfo.grade}</span>
              </div>
            }
            items={itemsOption} column={2}
          />
        </div>

        <div className='pdRL16 pdT16'>
          <div className='flex_row marB16'>
            <div className='marR13' style={{ display: userInfo.oneCardImg ? '' : 'none' }}>
              <div className='w162 h122'>
                <Image width='100%' height='100%' style={{ objectFit: 'cover', borderRadius: 8 }} src={userInfo.oneCardImg} />
              </div>
              <div className='tc lh22' style={{ color: colorTextTertiary }}>电子一卡通</div>
            </div>

            <div className='marR13' style={{ display: userInfo.carImg ? '' : 'none' }}>
              <div className='w162 h122'>
                <Image width='100%' height='100%' style={{ objectFit: 'cover', borderRadius: 8 }} src={userInfo.carImg} />
              </div>
              <div className='tc lh22' style={{ color: colorTextTertiary }}>车辆正侧面</div>
            </div>

            <div className='marR13' style={{ display: userInfo.side_pic ? '' : 'none' }}>
              <div className='w162 h122'>
                <Image width='100%' height='100%' style={{ objectFit: 'cover', borderRadius: 8 }} src={userInfo.side_pic} />
              </div>
              <div className='tc lh22' style={{ color: colorTextTertiary }}>车辆45°含车牌</div>
            </div>
          </div>

          <div className='marR13 marB16' style={{ display: userInfo.insuranceImg ? '' : 'none' }}>
            <div className='w162 h122'>
              <Image width='100%' height='100%' style={{ objectFit: 'cover', borderRadius: 8 }} src={userInfo.insuranceImg} />
            </div>
            <div className='w162 lh22  tc' style={{ color: colorTextTertiary }}>交强险保单</div>
          </div>

          {/* 行驶证 */}
          <div className='flex_row flex_wrap marB16'>
            {
              userInfo.drivingCardImg ?
                userInfo.drivingCardImg.map((val, idx) => {
                  return (
                    <div className={(idx + 1) % 3 === 0 ? '' : 'marR13'} key={idx}>
                      <div className='w162 h122'>
                        <Image width='100%' height='100%' style={{ objectFit: 'cover', borderRadius: 8 }} src={val} />
                      </div>
                      <div className='tc lh22' style={{ color: colorTextTertiary }}>{'行驶证' + (idx + 1)}</div>
                    </div>
                  )
                }) : ''
            }
          </div>

          {/* 驾驶证 */}
          <div className='flex_row flex_wrap'>
            {
              userInfo.driverCardImg ?
                userInfo.driverCardImg.map((val, idx) => {
                  return (
                    <div className={(idx + 1) % 3 === 0 ? '' : 'marR13'} key={idx}>
                      <div className='w162 h122'>
                        <Image width='100%' height='100%' style={{ objectFit: 'cover', borderRadius: 8 }} src={val} />
                      </div>
                      <div className='tc lh22' style={{ color: colorTextTertiary }}>{'驾驶证' + (idx + 1)}</div>
                    </div>
                  )
                }) : ''
            }
          </div>

        </div>
      </Spin>
    </div>
  )
}
export default UserInfo;