//审核学员
// 编辑
import { useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useEffect, useImperativeHandle, useRef } from 'react'
import { theme, Form, Select, DatePicker, App, Spin, Input, InputNumber, Upload, Image } from 'antd'
import { PlusOutlined, LoadingOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'
import { handleAuditInfoFun } from '@/server/electric_vehicle_audit'
import { uploadFileFun } from '@/server/common'
import { baseUrl } from "@/server/index.js"
import dayjs from 'dayjs';
import './index.css'

function AuditStudent(props, ref) {
  useImperativeHandle(props.onRef, () => {
    return {
      submit,
      reset
    };
  });

  const { message } = App.useApp();
  const {
    token: { colorText, colorPrimary, colorTextSecondary, colorTextTertiary, colorError },
  } = theme.useToken(); //获取主题颜色

  const chooseId = props.id;//选择的id
  const submitType = props.type;//提交类型

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);//加载动画

  const [imgVisible, setImgVisible] = useState({//
    visible: false,
    url: '',
  });
  const [chooseUserType, setChooseUserType] = useState();//选择的用户类型
  const [chooseCarType, setChooseCarType] = useState();//选择的车辆类型

  //选项
  const [userTypeOption, setUserTypeOption] = useState([]);//用户类型
  const [unitOption, setUnitOption] = useState([]);//单位
  const [carTypeOption, setCarTypeOption] = useState([]);//车辆类型 
  const [gradeTypeOption, setGradeTypeOption] = useState([]);//年级类型 
  const [collegeOption, setCollegeOption] = useState([]);//学院
  const [ageLimitOption, setAgeLimitOption] = useState();//年限

  //图片
  const [oneCardImg, setOneCardImg] = useState('');//一卡通
  const [oneCardImgOperate, setOneCardImgOperate] = useState(false);//一卡通操作是否显示
  const [carImg, setCarImg] = useState('');//车辆正侧面
  const [sidePic, setSidePic] = useState('');//车辆45°含车牌
  const [carImgOperate, setCarImgOperate] = useState(false);//车辆照片操作是否显示
  const [sidePicOperate, setSidePicOperate] = useState(false);//车辆照片操作是否显示
  const [drivingCardImg, setDrivingCardImg] = useState([]);//行驶证
  const [driverCardImg, setDriverCardImg] = useState([]);//驾驶证
  const [insuranceImg, setInsuranceImg] = useState('');//交强险
  const [insuranceImgOperate, setInsuranceImgOperate] = useState(false);//交强险操作是否显示

  //上传图片加载中
  const [drivingCardLoad, setDrivingCardLoad] = useState(false);//行驶证
  const [driverCardLoad, setDriverCardLoad] = useState(false);//行驶证
  const [oneCardLoad, setOneCardLoad] = useState(false);//一卡通
  const [carLoad, setCarLoad] = useState(false);//车辆正侧面
  const [sidePicLoad, setSidePicLoad] = useState(false);//车辆正侧面
  const [insuranceLoad, setInsuranceLoad] = useState(false);//交强险

  //ref
  const drivingCardImgRef = useRef();//行驶证
  const driverCardImgRef = useRef();//驾驶证

  ///初始化
  const initialize = async () => {
    setLoading(true);
    let res = await handleAuditInfoFun({
      type: 0,
      wId: chooseId
    });
    console.log('res', res);
    setLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    let dictionary = res.data.sjzd;
    console.log('字典', dictionary);
    let info = res.data.data && res.data.data.length > 0 ? res.data.data[0] : {};
    let drivers_license = [];
    let driving_license = [];
    if (info && info.drivers_license) {
      for (let i = 0; i < info.drivers_license.length; i++) {
        let val = info.drivers_license[i];
        drivers_license.push({ url: val, show: false })
      }
    }
    if (info && info.driving_license) {
      for (let i = 0; i < info.driving_license.length; i++) {
        let val = info.driving_license[i];
        driving_license.push({ url: val, show: false })
      }
    }

    let userType = dictionary.role.map((val, idx) => {
      return {
        label: val.xmmc,
        value: Number(val.xmbm)
      }
    });
    let unit = dictionary.unit.map((val, idx) => {
      return {
        label: val.xmmc,
        value: Number(val.xmbm)
      }
    });
    let carType = dictionary.car_type.map((val, idx) => {
      return {
        label: val.xmmc,
        value: Number(val.xmbm)
      }
    });
    let graType = dictionary.xsnj.map((val, idx) => {//年级 字典
      return {
        label: val.xmmc,
        value: Number(val.xmbm)
      }
    });
    let college = dictionary.college.map((val, idx) => {
      return {
        label: val.xmmc,
        value: Number(val.xmbm)
      }
    });
    let ageLimit = dictionary.yxqnx.map((val, idx) => {
      return {
        label: val.xmmc,
        value: Number(val.xmbm)
      }
    });


    setUserTypeOption(userType);
    setUnitOption(unit);
    setCarTypeOption(carType);
    setGradeTypeOption(graType);
    setCollegeOption(college);
    setAgeLimitOption(ageLimit);

    setOneCardImg(info.campus_card ?? '');
    setInsuranceImg(info.compulsory_insurance);
    setCarImg(info.pic ?? '');
    setSidePic(info.side_pic ?? '');
    setChooseUserType(info.role ? Number(info.role) : '');
    setChooseCarType(info.car_type ? Number(info.car_type) : '');
    setDriverCardImg(drivers_license);
    setDrivingCardImg(driving_license);

    form.setFieldsValue({
      userType: Number(info.role),
      code: info.swid,
      name: info.name,
      phone: info.phone,
      unit: info.user_unit ? Number(info.user_unit) : null,
      carType: Number(info.car_type),
      gradeType: Number(info.grade),
      licensePlate: info.car_number,
      expirationDate: info.expiry_time ? dayjs(info.expiry_time) : null,
      carImg: info.pic,
      sidePic: info.side_pic,
      oneCardImg: info.campus_card,
      insuranceImg: info.compulsory_insurance,
      ageLimit: info.expiry ? Number(info.expiry) : null
    });
  }

  //上传前验证
  const imgBeforeUpload = (e) => {
    if (e.type !== 'image/jpeg' && e.type !== 'image/png') {
      message.error('只能上传jpeg/png格式的图片')
      return false;
    }
    // if (e.size > 2 * 1024 * 1024) {
    //   message.error('图片不能超过2M');
    //   return false;
    // }
  }

  //图片改变执行
  const imgHandleChange = (info, fun, load) => {
    load(true);
    if (info.file.status === 'done') {
      let res = info.file.response; //接口返回的数据
      load(false);
      if (res.ret !== 200) {
        message.warning(info.file.response.msg || '数据跑丢了~');
        return;
      }
      fun(res.data.path);//修改值
    }
    if (info.file.status === 'error') {
      load(false);
    }
  }

  //上传选择的图片
  const uploadChooseImg = async (file, load) => {
    load(true);
    let res = await uploadFileFun({
      file: file
    });
    load(false);
    if (res.ret !== 200) {
      message.warning(info.file.response.msg || '数据跑丢了~');
      return '';
    }
    return res.data.path;
  }

  // 提交
  const onFinish = async (e) => {
    let driving_license = '';
    let drivers_license = '';
    if (drivingCardImg.length > 0 && chooseCarType !== 1) {
      let arr = drivingCardImg.map((val) => {
        return val.url
      });
      driving_license = arr.join(',');
    }
    if (driverCardImg.length > 0 && (chooseCarType === 3 || chooseCarType === 4 || chooseCarType === 5)) {
      let arr = driverCardImg.map((val) => {
        return val.url
      });
      drivers_license = arr.join(',');
    }
    let params = {
      type: submitType,
      wId: chooseId,
      role: e.userType,//用户类型
      swid: e.userType === 2 || e.userType === 3 || e.userType === 5 ? e.code : '',//工号
      name: e.name,//姓名
      phone: e.phone,//电话
      user_unit: e.unit,//单位
      car_type: e.carType,//车辆类型
      grade: e.userType === 3 ? e.gradeType : null,//年级
      car_number: e.carType !== 1 ? e.licensePlate : '',//车牌号
      expiry_time: e.expirationDate ? dayjs(e.expirationDate).format('YYYY-MM-DD') : '',//有效期
      campus_card: oneCardImg,//一卡通
      pic: carImg,//车辆正侧面
      side_pic: sidePic,//车辆正侧面
      driving_license: e.carType !== 1 ? driving_license : '',//行驶证
      drivers_license: e.carType === 3 || e.carType === 4 || e.carType === 5 ? drivers_license : '',//驾驶证
      compulsory_insurance: e.carType === 3 || e.carType === 4 || e.carType === 5 ? insuranceImg : '',
      expiry: e.ageLimit,//有效年限
    }
    setLoading(true);
    let res = await handleAuditInfoFun(params);
    setLoading(false);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
      return;
    }
    message.success('审核通过');
    props.close(true);
  }

  //重置表单
  const reset = () => {
    form.resetFields();
  }

  //提交
  const submit = () => {
    form.submit();
  }

  useEffect(() => {
    initialize();
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Spin spinning={loading}>
        <Form
          layout='vertical'
          form={form}
          onFinish={onFinish}
        >
          <div className='flex_row'>
            <Form.Item label="类型" className='marR16' name='userType' rules={[
              {
                required: true,
                message: '请选择类型!',
              },
            ]}>
              <Select
                style={{ width: 162 }}
                allowClear
                placeholder="-"
                options={userTypeOption}
                onChange={(e) => {
                  setChooseUserType(e);
                  form.setFieldsValue({
                    unit: null,
                    code: null,
                    gradeType: null,
                    ageLimit: null
                  })
                }}
              />
            </Form.Item>
            <Form.Item label={chooseUserType === 3 ? '学号' : chooseUserType === 2 ? '工号' : chooseUserType === 5 ? '编号' : ''} className='flex_auto' name='code'
              hidden={chooseUserType === 2 || chooseUserType === 3 || chooseUserType === 5 ? '' : true}
              rules={[
                {
                  required: chooseUserType === 2 || chooseUserType === 3 || chooseUserType === 5 ? true : false,
                  message: '请输入' + (chooseUserType === 3 ? '学号' : chooseUserType === 2 ? '工号' : chooseUserType === 5 ? '编号' : '') + '!',
                },
              ]}>
              <Input placeholder="-" />
            </Form.Item>
          </div>

          <div className='flex_row'>
            <Form.Item label="姓名" className='marR16' name='name' rules={[
              {
                required: true,
                message: '请输入姓名!',
              },
            ]}>
              <Input style={{ width: 162 }} placeholder="-" />
            </Form.Item>

            <Form.Item label="手机号" className='flex_auto' name='phone' rules={[
              {
                required: true,
                message: '请输入手机号!',
              },
              {
                pattern: new RegExp(/^1[3456789]\d{9}$/),
                message: '请输入正确的手机号',
              },
            ]}>
              <Input placeholder="-" />
            </Form.Item>
          </div>

          <Form.Item label={chooseUserType === 3 ? '学院' : '二级单位'} name='unit'
            rules={[
              {
                required: true,
                message: '请选择' + (chooseUserType === 3 ? '学院!' : '二级单位!'),
              },
            ]}>
            <Select
              showSearch
              allowClear
              placeholder="-"
              options={chooseUserType === 3 ? collegeOption : unitOption}
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
            // filterSort={(optionA, optionB) =>
            //   (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            // }
            />
          </Form.Item>

          <div className='flex_row'>
            <Form.Item label="年级" className='marR16' name='gradeType'
              hidden={chooseUserType === 3 ? '' : true}
              rules={[
                {
                  required: chooseUserType === 3,
                  message: '请选择年级!',
                },
              ]}>
              <Select style={{ width: 162 }} allowClear placeholder="-" options={gradeTypeOption} onChange={(e) => {
                setChooseCarType(e);
                form.setFieldsValue({
                  insuranceImg: null,
                })
              }} />
            </Form.Item>
          </div>

          <div className='flex_row'>
            <Form.Item label="车辆类型" className='marR16' name='carType' rules={[
              {
                required: true,
                message: '请选择车辆类型!',
              },
            ]}>
              <Select style={{ width: 162 }} allowClear placeholder="-" options={carTypeOption} onChange={(e) => {
                setChooseCarType(e);
                form.setFieldsValue({
                  insuranceImg: null,
                })
              }} />
            </Form.Item>

            <Form.Item label="车牌号" className='flex_auto' name='licensePlate'
              hidden={chooseCarType !== 1 ? '' : true}
              rules={[
                { required: chooseCarType !== 1 ? true : false, message: '请输入车牌号!', }
              ]}
            >
              <Input placeholder="-" />
            </Form.Item>
          </div>

          {/* 年限 */}
          <div className='flex_row'>
            <Form.Item label="有效年限" className='marR16' name='ageLimit'
              hidden={chooseUserType === 3 ? '' : true}
              rules={[
                {
                  required: chooseUserType === 3,
                  message: '请选择有效年限!',
                },
              ]}>
              <Select style={{ width: 162 }} allowClear placeholder="-" options={ageLimitOption} onChange={(e, option) => {
                console.log(e, option);
                if (chooseUserType === 3) {
                  if (option.label) {
                    let year = option.label.slice(0, 4);
                    let date = year + '-07-31';
                    form.setFieldsValue({
                      expirationDate: dayjs(date),
                    });
                  }
                }
              }} />
            </Form.Item>

            <Form.Item label="有效期" name='expirationDate'
              rules={[
                {
                  required: true,
                  message: '请选择有效期!',
                },
              ]}>
              <DatePicker style={{ width: 162 }} />
            </Form.Item>
          </div>


          <div className='marB24' style={{ display: chooseUserType === 3 || chooseUserType === 5 ? '' : 'none' }}></div>

          <div className='flex_row'>
            <Form.Item label="电子一卡通" name='oneCardImg'
              rules={[{ required: true, message: '请选择图片', }]}
            >
              <Upload
                className='marR16'
                name="file"
                showUploadList={false}
                action={baseUrl + "/?service=Admin.Clgls.Update_Pic"}
                maxCount={1}
                beforeUpload={imgBeforeUpload}
                onChange={(info) => {
                  imgHandleChange(info, setOneCardImg, setOneCardLoad);
                }}
              >
                {oneCardImg ? (
                  <div className='upload_img' style={{ position: 'relative' }}
                    onMouseOver={() => {
                      setOneCardImgOperate(true);
                    }}
                    onMouseOut={() => {
                      setOneCardImgOperate(false);
                    }}
                  >
                    <img src={oneCardImg} alt='oneCardImg' style={{ width: '100%', height: '100%', objectFit: 'cover' }}></img>
                    <div className='pointer flex_row justify_center align_center pos_abs w144 h104'
                      style={{ top: 8, background: colorTextTertiary, display: oneCardImgOperate ? '' : 'none' }}>
                      <span className='mar8' onClick={(e) => {
                        e.stopPropagation();
                        setOneCardImgOperate(false);
                        let info = { ...imgVisible };
                        info.visible = true;
                        info.url = oneCardImg;
                        setImgVisible(info);
                      }}>
                        <EyeOutlined style={{ color: 'white' }} className='fs22 opa45 icon_s' />
                      </span>
                      <span className='mar8' onClick={(e) => {
                        e.stopPropagation();
                        setOneCardImgOperate(false);
                        setOneCardImg('');
                        form.setFieldsValue({
                          oneCardImg: ''
                        });
                      }}>
                        <DeleteOutlined style={{ color: 'white' }} className='fs22 opa45 icon_s' />
                      </span>

                    </div>
                  </div>
                ) : (
                  <div>
                    {
                      oneCardLoad ?
                        <div className='upload_img_div'>
                          <LoadingOutlined style={{ color: colorTextSecondary, }} className='fs30' />
                        </div> :
                        <div className='upload_img_div'>
                          <PlusOutlined className='marB8' />
                          <div style={{ color: colorTextSecondary, }} > Upload </div>
                        </div>
                    }
                  </div>
                )}
              </Upload>
            </Form.Item>

            <Form.Item label="车辆正侧面" name='carImg'
              rules={[{ required: true, message: '请选择图片', }]}
            >
              <Upload
                name="file"
                showUploadList={false}
                action={baseUrl + "/?service=Admin.Clgls.Update_Pic"}
                maxCount={1}
                beforeUpload={imgBeforeUpload}
                onChange={(info) => {
                  imgHandleChange(info, setCarImg, setCarLoad);
                }}
              >
                {carImg ? (
                  <div className='upload_img' style={{ position: 'relative' }}
                    onMouseOver={() => {
                      setCarImgOperate(true);
                    }}
                    onMouseOut={() => {
                      setCarImgOperate(false);
                    }}
                  >
                    <img src={carImg} alt='carImg' style={{ width: '100%', height: '100%', objectFit: 'cover' }}></img>
                    <div className='pointer flex_row justify_center align_center pos_abs w144 h104'
                      style={{ top: 8, background: colorTextTertiary, display: carImgOperate ? '' : 'none' }}>
                      <span className='mar8' onClick={(e) => {
                        e.stopPropagation();
                        let info = { ...imgVisible };
                        info.visible = true;
                        info.url = carImg;
                        setCarImgOperate(false);
                        setImgVisible(info);
                      }}>
                        <EyeOutlined style={{ color: 'white' }} className='fs22 opa45 icon_s' />
                      </span>
                      <span className='mar8' onClick={(e) => {
                        e.stopPropagation();
                        setCarImgOperate(false);
                        setCarImg('');
                        form.setFieldsValue({
                          carImg: ''
                        });
                      }}>
                        <DeleteOutlined style={{ color: 'white' }} className='fs22 opa45 icon_s' />
                      </span>

                    </div>
                  </div>
                ) : (
                  <div>
                    {
                      carLoad ?
                        <div className='upload_img_div'>
                          <LoadingOutlined style={{ color: colorTextSecondary, }} className='fs30' />
                        </div> :
                        <div className='upload_img_div'>
                          <PlusOutlined className='marB8' />
                          <div style={{ color: colorTextSecondary, }} > Upload </div>
                        </div>
                    }
                  </div>
                )}
              </Upload>
            </Form.Item>
          </div>
          <Form.Item label="车辆45°含车牌" name='sidePic'
            rules={[{ required: true, message: '请选择图片', }]}
          >
            <Upload
              name="file"
              showUploadList={false}
              action={baseUrl + "/?service=Admin.Clgls.Update_Pic"}
              maxCount={1}
              beforeUpload={imgBeforeUpload}
              onChange={(info) => {
                imgHandleChange(info, setSidePic, setSidePicLoad);
              }}
            >
              {sidePic ? (
                <div className='upload_img' style={{ position: 'relative' }}
                  onMouseOver={() => {
                    setSidePicOperate(true);
                  }}
                  onMouseOut={() => {
                    setSidePicOperate(false);
                  }}
                >
                  <img src={sidePic} alt='sidePic' style={{ width: '100%', height: '100%', objectFit: 'cover' }}></img>
                  <div className='pointer flex_row justify_center align_center pos_abs w144 h104'
                    style={{ top: 8, background: colorTextTertiary, display: sidePicOperate ? '' : 'none' }}>
                    <span className='mar8' onClick={(e) => {
                      e.stopPropagation();
                      let info = { ...imgVisible };
                      info.visible = true;
                      info.url = sidePic;
                      setSidePicOperate(false);
                      setImgVisible(info);
                    }}>
                      <EyeOutlined style={{ color: 'white' }} className='fs22 opa45 icon_s' />
                    </span>
                    <span className='mar8' onClick={(e) => {
                      e.stopPropagation();
                      setSidePicOperate(false);
                      setSidePic('');
                      form.setFieldsValue({
                        sidePic: ''
                      });
                    }}>
                      <DeleteOutlined style={{ color: 'white' }} className='fs22 opa45 icon_s' />
                    </span>

                  </div>
                </div>
              ) : (
                <div>
                  {
                    carLoad ?
                      <div className='upload_img_div'>
                        <LoadingOutlined style={{ color: colorTextSecondary, }} className='fs30' />
                      </div> :
                      <div className='upload_img_div'>
                        <PlusOutlined className='marB8' />
                        <div style={{ color: colorTextSecondary, }} > Upload </div>
                      </div>
                  }
                </div>
              )}
            </Upload>
          </Form.Item>

          <Form.Item label={<div><span className='marR4' style={{ fontFamily: 'SimSun,sans-serif', color: colorError }}>*</span>行驶证</div>}
            hidden={chooseCarType !== 1 ? '' : true}
          >
            <Form.Item noStyle name='drivingCardImg' hidden={true}
              rules={[{ required: chooseCarType !== 1 && drivingCardImg.length === 0 ? true : false, message: '请选择图片', }]}
            >
              <Input />
            </Form.Item>
            <div className='flex_row flex_wrap'>
              {drivingCardImg.length > 0 ? drivingCardImg.map((val, idx) => {
                return <div key={idx} className={idx % 2 === 0 ? 'marR16 upload_img' : 'marB16 upload_img'}
                  style={{ position: 'relative' }}
                  onMouseOver={() => {
                    let arr = [...drivingCardImg];
                    arr[idx].show = true;
                    setDrivingCardImg(arr);
                  }}
                  onMouseOut={() => {
                    let arr = [...drivingCardImg];
                    arr[idx].show = false;
                    setDrivingCardImg(arr);
                  }}
                >
                  <img src={val.url} alt={'drivingCardImg' + idx} style={{ width: '100%', height: '100%', objectFit: 'cover' }}></img>
                  <div className='flex_row justify_center align_center pos_abs w144 h104'
                    style={{ top: 8, background: colorTextTertiary, display: val.show ? '' : 'none' }}>
                    <span className='mar8 pointer' onClick={() => {
                      let info = { ...imgVisible };
                      info.visible = true;
                      info.url = val.url;
                      setImgVisible(info);
                    }}>
                      <EyeOutlined style={{ color: 'white' }} className='fs22 opa45 icon_s' />
                    </span>
                    <span className='mar8 pointer' onClick={() => {
                      let arr = [...drivingCardImg];
                      arr.splice(idx, 1);
                      setDrivingCardImg(arr);
                    }}>
                      <DeleteOutlined style={{ color: 'white' }} className='fs22 opa45 icon_s' />
                    </span>
                  </div>
                </div>
              }) : ''}
              {
                drivingCardLoad ?
                  <div className='upload_img_div'>
                    <LoadingOutlined style={{ color: colorTextSecondary, }} className='fs30' />
                  </div> :
                  <div className='upload_img_div' onClick={() => {
                    drivingCardImgRef.current.click();
                  }}>
                    <PlusOutlined className='marB8' />
                    <div style={{ color: colorTextSecondary, }} > Upload </div>
                  </div>

              }
            </div>
          </Form.Item>

          <Form.Item label={<div><span className='marR4' style={{ fontFamily: 'SimSun,sans-serif', color: colorError }}>*</span>驾驶证</div>}
            hidden={chooseCarType === 3 || chooseCarType === 4 || chooseCarType === 5 ? '' : true}
          >
            <Form.Item noStyle name='driverCardImg' hidden={true}
              rules={[{ required: (chooseCarType === 3 || chooseCarType === 4 || chooseCarType === 5) && driverCardImg.length === 0 ? true : false, message: '请选择图片', }]}
            >
              <Input />
            </Form.Item>
            <div className='flex_row flex_wrap'>
              {driverCardImg.length > 0 ? driverCardImg.map((val, idx) => {
                return <div key={idx} className={idx % 2 === 0 ? 'marR16 upload_img' : 'marB16 upload_img'}
                  style={{ position: 'relative' }}
                  onMouseOver={() => {
                    let arr = [...driverCardImg];
                    arr[idx].show = true;
                    setDriverCardImg(arr);
                  }}
                  onMouseOut={() => {
                    let arr = [...driverCardImg];
                    arr[idx].show = false;
                    setDriverCardImg(arr);
                  }}
                >
                  <img src={val.url} alt={'driverCardImg' + idx} style={{ width: '100%', height: '100%', objectFit: 'cover' }}></img>
                  <div className='flex_row justify_center align_center pos_abs w144 h104'
                    style={{ top: 8, background: colorTextTertiary, display: val.show ? '' : 'none' }}>
                    <span className='mar8 pointer' onClick={() => {
                      let info = { ...imgVisible };
                      info.visible = true;
                      info.url = val.url;
                      setImgVisible(info);
                    }}>
                      <EyeOutlined style={{ color: 'white' }} className='fs22 opa45 icon_s' />
                    </span>
                    <span className='mar8 pointer' onClick={() => {
                      let arr = [...driverCardImg];
                      arr.splice(idx, 1);
                      setDriverCardImg(arr);
                    }}>
                      <DeleteOutlined style={{ color: 'white' }} className='fs22 opa45 icon_s' />
                    </span>

                  </div>
                </div>
              }) : ''}
              {
                driverCardLoad ?
                  <div className='upload_img_div'>
                    <LoadingOutlined style={{ color: colorTextSecondary, }} className='fs30' />
                  </div> :
                  <div className='upload_img_div' onClick={() => {
                    driverCardImgRef.current.click();
                  }}>
                    <PlusOutlined className='marB8' />
                    <div style={{ color: colorTextSecondary, }} > Upload </div>
                  </div>

              }
            </div>
          </Form.Item>

          <Form.Item label="交强险保单" name='insuranceImg'
            hidden={(chooseCarType === 3 || chooseCarType === 4 || chooseCarType === 5) ? '' : true}
            rules={[{ required: chooseCarType === 5 ? true : false, message: '请选择图片', }]}
          >
            <Upload
              className='marR16'
              name="file"
              showUploadList={false}
              action={baseUrl + "/?service=Admin.Clgls.Update_Pic"}
              maxCount={1}
              beforeUpload={imgBeforeUpload}
              onChange={(info) => {
                imgHandleChange(info, setInsuranceImg, setInsuranceLoad);
              }}
            >
              {insuranceImg ? (
                <div className='upload_img' style={{ position: 'relative' }}
                  onMouseOver={() => {
                    setInsuranceImgOperate(true);
                  }}
                  onMouseOut={() => {
                    setInsuranceImgOperate(false);
                  }}
                >
                  <img src={insuranceImg} alt='insuranceImg' style={{ width: '100%', height: '100%', objectFit: 'cover' }}></img>
                  <div className='pointer flex_row justify_center align_center pos_abs w144 h104'
                    style={{ top: 8, background: colorTextTertiary, display: insuranceImgOperate ? '' : 'none' }}>
                    <span className='mar8' onClick={(e) => {
                      e.stopPropagation();
                      setInsuranceImgOperate(false);
                      let info = { ...imgVisible };
                      info.visible = true;
                      info.url = insuranceImg;
                      setImgVisible(info);
                    }}>
                      <EyeOutlined style={{ color: 'white' }} className='fs22 opa45 icon_s' />
                    </span>
                    <span className='mar8' onClick={(e) => {
                      e.stopPropagation();
                      setInsuranceImgOperate(false);
                      setInsuranceImg('');
                      form.setFieldsValue({
                        insuranceImg: ''
                      });
                    }}>
                      <DeleteOutlined style={{ color: 'white' }} className='fs22 opa45 icon_s' />
                    </span>

                  </div>
                </div>
              ) : (
                <div>
                  {
                    insuranceLoad ?
                      <div className='upload_img_div'>
                        <LoadingOutlined style={{ color: colorTextSecondary, }} className='fs30' />
                      </div> :
                      <div className='upload_img_div'>
                        <PlusOutlined className='marB8' />
                        <div style={{ color: colorTextSecondary, }} > Upload </div>
                      </div>
                  }
                </div>
              )}
            </Upload>
          </Form.Item>
        </Form>

        {/* 行驶证上传图片 */}
        <input ref={drivingCardImgRef} type='file' accept="image/png, image/jpeg" style={{ display: 'none' }}
          onChange={async (e) => {
            let file = e.target.files[0];
            let judge = imgBeforeUpload(file);
            if (judge !== false) {
              console.log('执行');
              let path = await uploadChooseImg(file, setDrivingCardLoad);
              if (path) {
                let arr = [...drivingCardImg];
                arr.push({
                  url: path,
                  show: false
                });
                setDrivingCardImg(arr);
              }
            }
            drivingCardImgRef.current.value = '';
          }}
        />

        {/* 驾驶证上传图片 */}
        <input ref={driverCardImgRef} type='file' accept="image/png, image/jpeg" style={{ display: 'none' }}
          onChange={async (e) => {
            let file = e.target.files[0];
            let judge = imgBeforeUpload(file);
            if (judge !== false) {
              console.log('执行');
              let path = await uploadChooseImg(file, setDriverCardLoad);
              if (path) {
                let arr = [...driverCardImg];
                arr.push({
                  url: path,
                  show: false
                });
                setDriverCardImg(arr);
              }
            }
            driverCardImgRef.current.value = '';
          }}
        />

        <Image style={{ display: 'none', }}
          preview={{
            visible: imgVisible.visible,
            src: imgVisible.url,
            onVisibleChange: (value) => {
              let info = { ...imgVisible };
              info.visible = false;
              setImgVisible(info);
            },
          }}
        />
      </Spin>
    </div>
  )
}
export default AuditStudent;