// 电瓶车管理
import { useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react'
import { Card, theme, Table, Input, Button, Divider, Pagination, Select, Empty, Modal, Radio, Image, QRCode, App, Drawer, Space } from 'antd'
import { ExclamationCircleFilled, QrcodeOutlined, ExportOutlined, PictureTwoTone, SearchOutlined } from '@ant-design/icons'
import { getElectricVehicleAuditFun } from '@/server/electric_vehicle_audit'
import { setUserStateFun } from '@/server/vehicle'
import moment from 'moment'
import AuditStudent from '../electric_vehicle_audit/audit_student';
import baseUrl from '@/server/index.js'

function Vehicle() {
  const navigate = useNavigate();//路由跳转
  const location = useLocation();//路由接受参数

  const { message } = App.useApp();
  const {
    token: { colorText, colorPrimary, colorTextQuaternary, colorTextTertiary, colorError },
  } = theme.useToken(); //获取主题颜色

  const { confirm } = Modal;
  const searchInput = useRef(null);//搜索框数据

  const [loading, setLoading] = useState(false)//加载中···
  const [siftData, setSiftData] = useState({//搜索列表
    page: 1,
    limit: 10,
    carType: 2,
    type: null,
    name: null,
    phone: null,
    id_effective: null,
  });

  const [auditDrawer, setAuditDrawer] = useState({//审核抽屉
    isOpen: false,
    id: '',//选择的学员id
  });

  const [imgVisible, setImgVisible] = useState({//图片展示
    visible: false,
    url: '',
  });

  const [QRCodeInfo, setQRCodeInfo] = useState('');//二维码内容

  const [typeDictionary, setTypeDictionary] = useState([])//类型  字典
  const [unitDictionary, setUnitDictionary] = useState([])//单位  字典
  const [vehicleDictionary, setVehicleDictionary] = useState([])//车辆 字典
  const [validityPeriodDictionary, setValidityPeriodDictionary] = useState([])//有效期 字典

  const [tableData, setTableData] = useState([])//table数据
  const [total, setTotal] = useState(0);//总条数

  const auditStudentRef = useRef();//审核学员

  //获取表格数据
  const getTableData = async (sift, show = true) => {
    if (show) { setLoading(true); }
    let res = await getElectricVehicleAuditFun({
      type: 3,
      page: sift.page,
      perpage: sift.limit,
      name: sift.name,
      phone: sift.phone,
      role: sift.type,
      car_type: sift.carType,
      id_effective: sift.id_effective ? sift.id_effective : null
    });
    console.log(res);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
    }
    let audit = [];
    if (res.data.data.items && res.data.data.items.length > 0) {
      for (let i = 0; i < res.data.data.items.length; i++) {
        let val = res.data.data.items[i];
        let obj = {
          id: val.wId,
          codeId: val.code_id,
          userType: val.role,
          name: val.name,
          phone: val.phone,
          unit: val.user_unit,
          code: val.swid,
          carType: val.car_type_txt,
          carTypeValue: val.car_type,
          licensePlate: val.car_number,
          carImg: val.pic,
          expirationDate: val.expiry_time,
          submitDate: val.submit_time,
          state: val.is_approved_txt,
          stateValue: Number(val.is_approved),
          auditDate: val.examine_time,
          score: Number(val.score)
        }
        audit.push(obj);
      }
    }

    // 车辆 字典
    let vehType = res.data.sjzd.car_type.map((res) => {
      return {
        label: res.xmmc,
        value: Number(res.xmbm)
      }
    });
    vehType.push({
      label: '0积分',
      value: 6
    })
    setVehicleDictionary([...vehType])

    // 有效期 字典
    let valType = res.data.sjzd.yxrq.map((res) => {
      return {
        text: res.xmmc,
        value: Number(res.xmbm)
      }
    })
    setValidityPeriodDictionary([...valType])

    // 类型  字典
    let type = res.data.sjzd.role.map((res) => {
      return {
        text: res.xmmc,
        value: Number(res.xmbm)
      }
    })
    setTypeDictionary([...type])

    // 单位  字典
    let unit = res.data.sjzd.user_unit.map((res) => {
      return {
        text: res.xmmc,
        value: Number(res.xmbm)
      }
    })
    setUnitDictionary([...unit])

    setTableData(audit);
    setTotal(res.data.data.total);
    setLoading(false);
  }

  //导出(获取后台的csv文件流)
  const exportExcel = async () => {
    setLoading(true)
    let date = moment().format('YYYYMMDD');
    let url = '/?service=Admin.Clgls.GetAllVehicle_export'
    fetch(url, { // url为后端接口地址
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);
        link.download = `电动车${date}.csv`
        document.body.appendChild(link);
        link.click();
        // 释放的 URL 对象以及移除 a 标签
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }).finally(() => {
        setLoading(false)
      });
  }

  // 输入框搜索
  const handleSearch = (confirm, dataIndex) => {
    confirm()
  };

  //重置
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };

  //姓名、手机、学院 输入框搜索
  const getColumSearch = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      < div className='pd8' onKeyDown={(e) => e.stopPropagation()} >
        <div>
          <Input ref={searchInput}
            placeholder={`搜索 ${dataIndex}`}
            value={selectedKeys}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(confirm)}
          />
        </div>
        <Space className='pdT8 flex jcsb'>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            搜索
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            重置
          </Button>
        </Space>
      </ div >
    ),
    filterIcon: (filtered) => (<SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  })

  const columns = [//表头
    {
      title: "ID",
      dataIndex: "codeId",
      render: (text, record) => {
        return (
          text ? <div>{text}</div >
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "二维码",
      width: 100,
      align: 'center',
      render: (text, record) => {
        return (
          record.codeId ? <div>
            <div className='pointer' style={{ color: colorPrimary }} onClick={() => {
              downloadQRCode('QRCode' + record.id, record.name + '_' + record.codeId);
            }}><QrcodeOutlined /></div>
            <div id={'QRCode' + record.id} style={{ display: 'none' }}><QRCode type="svg" value={record.codeId} bordered={false} /></div>
          </div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "类型",
      dataIndex: "userType",
      render: (text, record) => {
        return (
          text ? <div>{text}</div >
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
      filterMultiple: false,
      filters: typeDictionary
    },
    {
      title: "姓名",
      dataIndex: "name",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
      ...getColumSearch('姓名')
    },
    {
      title: "手机",
      dataIndex: "phone",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
      ...getColumSearch('手机')
    },
    {
      title: "学号/工号",
      dataIndex: "code",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "学院/单位",
      dataIndex: "unit",
      render: (text, record) => {
        return (
          text ? <div>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
      // ...getColumUnitSearch('学院/单位')
    },
    {
      title: "车辆图片",
      dataIndex: "carImg",
      width: 90,
      align: 'center',
      render: (text, record) => {
        return (
          text ?
            <div className='pointer' onClick={() => {
              let info = { ...imgVisible };
              info.visible = true;
              info.url = text;
              setImgVisible(info);
            }}><PictureTwoTone /></div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "有效期",
      dataIndex: "expirationDate",
      align: 'left',
      render: (text, record) => {
        return (
          text
            ? <div style={{ color: text === '过期' ? colorError : '' }}>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
      filterMultiple: false,
      filters: validityPeriodDictionary
    },
    {
      title: "积分",
      dataIndex: "score",
    },
    // {
    //   title: "启用",
    //   dataIndex: "isEnable",
    //   render: (text, record) => {
    //     return (
    //       <Switch checked={record.isEnable} onChange={(e) => {
    //         console.log(e);
    //       }} />
    //     )
    //   },
    // }, 
    // {
    //   title: "提交日期",
    //   dataIndex: "submitDate",
    //   type: '',
    //   render: (text, record) => {
    //     return (
    //       text ? <div style={{ color: colorTextTertiary }}>{text}</div>
    //         : <div style={{ color: colorError }}>-</div>
    //     )
    //   },
    // },
    {
      title: "审核日期",
      dataIndex: "auditDate",
      type: '',
      render: (text, record) => {
        return (
          text ? <div style={{ color: colorTextTertiary }}>{text}</div>
            : <div style={{ color: colorTextQuaternary }}>-</div>
        )
      },
    },
    {
      title: "操作",
      render: (text, record) => {
        return <div>
          <span className='pointer' style={{ color: colorPrimary }} onClick={() => {
            let info = { ...auditDrawer };
            info.isOpen = true;
            info.id = record.id;
            setAuditDrawer(info);
          }}>
            编辑
          </span>
          <Divider type="vertical" />
          <span
            className='pointer'
            style={{ color: colorPrimary }}
            onClick={() => {
              confirm({
                title: '更换ID',
                icon: <ExclamationCircleFilled />,
                content: '该学生的ID将会改变，请谨慎操作!!!',
                okText: '更换ID',
                okType: "primary",
                cancelText: "容我想想~",
                async onOk() {
                  let obj = {
                    wId: record.id,
                    type: 1,
                  }
                  let res = await setUserStateFun(obj);
                  if (res.ret === 200) {
                    getTableData(siftData, false);
                    message.success("更换ID成功")
                  } else {
                    message.warning(res.msg || '数据跑丢了')
                  }
                },
                onCancel() { },
              });
            }}>
            更换ID
          </span>
          <Divider type="vertical" />
          <span className='pointer' style={{ color: colorPrimary }} onClick={() => {
            confirm({
              title: '是否删除?',
              icon: <ExclamationCircleFilled />,
              content: '该学生的车辆将删除，请谨慎操作!!!',
              okText: '删除',
              okType: "danger",
              cancelText: "容我想想~",
              async onOk() {
                await deleteUser(record.id);
              },
              onCancel() { },
            });
          }}>
            删除
          </span>
        </div>
      },
    },
  ];

  //根据id下载二维码
  const downloadQRCode = (id, fileName) => {
    const svg = document.getElementById(id)?.querySelector('svg');
    console.log(svg);
    if (svg) {
      let a = document.createElement('a');
      const s = new XMLSerializer().serializeToString(svg);
      a.href = `data:image/svg+xml;base64,${window.btoa(s)}`;
      a.download = fileName
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

  };

  //删除用户
  const deleteUser = async (id) => {
    setLoading(true);
    let res = await setUserStateFun({
      wId: id,
      type: 0,
    });
    setLoading(true);
    if (res.ret !== 200) {
      message.warning(res.msg || '数据跑丢了~');
    }
    message.success('删除成功');
    if (tableData.length === 1 && siftData.page > 1) {
      let sift = { ...siftData };
      sift.page = sift.page - 1;
      getTableData(sift);
      setSiftData(sift);
    } else {
      getTableData(siftData);
    }
  }

  //关闭审核抽屉
  const closeAuditDrawer = (refresh = false) => {
    let info = { ...auditDrawer };
    info.isOpen = false;
    setAuditDrawer(info);
    if (refresh) {
      console.log('执行');
      getTableData(siftData);
    }
  }

  useEffect(() => {
    getTableData(siftData)
    // eslint-disable-next-line
  }, [])

  return (
    <Card className='mar24'>
      <div className='marB16 flex jcsb'>
        <Radio.Group
          options={vehicleDictionary}
          value={siftData.carType}
          onChange={(e) => {
            console.log('e', e.target.value);
            let sif = siftData
            sif.page = 1
            sif.carType = e.target.value
            setSiftData({ ...sif })
            getTableData(sif)
          }}
          optionType="button"
        // buttonStyle="solid"
        />
        <Button
          className='marR8'
          icon={<ExportOutlined />}
          onClick={() => {
            exportExcel()
          }}
        >
          导出
        </Button>
      </div>


      <Table
        rowKey='id'
        loading={loading}
        columns={columns}
        pagination={false}
        dataSource={tableData}
        onChange={(pagination, filters, sorter) => {
          let sift = siftData;
          sift.page = 1;
          sift.name = filters.name ? filters.name[0] : '';
          sift.phone = filters.phone ? filters.phone[0] : '';
          sift.type = filters.userType ? filters.userType[0] : '';
          sift.id_effective = filters.expirationDate ? filters.expirationDate[0] : null
          setSiftData({ ...sift });
          getTableData(sift);
        }}
      />

      {/* 页脚 */}
      <div className='pdT16 tr'>
        <Pagination current={siftData.page} pageSize={siftData.limit} total={total} showTotal={total => `共 ${total} 条数据`}
          showSizeChanger={true}
          onChange={
            (current, pageSize) => { //选择当前页后执行
              let sift = { ...siftData }
              sift.page = current;
              if (sift.limit !== pageSize) {
                sift.page = 1;
                sift.limit = pageSize;
              }
              setSiftData({ ...sift })
              getTableData(sift)
            }
          }
        />
      </div>

      <Image style={{ display: 'none', }}
        preview={{
          visible: imgVisible.visible,
          src: imgVisible.url,
          onVisibleChange: (value) => {
            let info = { ...imgVisible };
            info.visible = false;
            setImgVisible(info);
          },
        }}
      />

      <Drawer title="编辑" width={388} placement="right" onClose={() => {
        closeAuditDrawer()
      }} open={auditDrawer.isOpen}
        destroyOnClose={true}
        extra={
          <Space>
            <Button type="primary" onClick={() => {
              auditStudentRef.current.submit();
            }}>
              提交
            </Button>
          </Space>
        }
      >
        <AuditStudent onRef={auditStudentRef} id={auditDrawer.id} type={2} close={closeAuditDrawer}></AuditStudent>
      </Drawer>

    </Card>
  )
}
export default Vehicle;